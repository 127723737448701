export const USE_CIT = {
  AUTO: 'AUTO',
  YES: true,
  NO: false,
};

export const TILE_TYPES = {
  RASTER: 'raster',
  VECTOR: 'vector',
  VECTORHARP: 'vectorHARP',
  RASTERV3: 'rasterv3',
};

export const TILE_LIBRARY_TYPES = {
  LEAFLET: 'leaflet',
  JSLA: 'jsla',
};

export const TILE_URLS = [
  'vector.hereapi.com',
  'origin.vector.hereapi.com',
  'vector.sit.hereapi.com',
  'origin.vector.sit.hereapi.com',
];
export const TILE_URLS_INTERNAL = [
  'vector.hereapi.com',
  'origin.vector.hereapi.com',
  'vector.sit.hereapi.com',
  'origin.vector.sit.hereapi.com',
  'vector.hereapi.cn'
];

export const TILE_PATHS = [
  '/v2/vectortiles/core/mc/',   // rest vectors
  '/v2/vectortiles/base/mc/'    // China vector
];

export const TILE_STYLE_URLS = [
  'https://js.api.here.com/v3/3.1/styles/omv/miami/normal.day.yaml',
  'https://js.api.here.com/v3/3.1/styles/omv/oslo/japan/normal.day.yaml',
];

export const TILE_STYLE_HARP_URLS = [
  'https://js.api.here.com/v3/3.1/styles/harp/oslo/normal.day.json',
  'https://js.api.here.com/v3/3.1/styles/harp/oslo/normal.night.json'
];
