import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const routes = get(props, 'result.raw.data.routes');
  if (!routes) {
    return [];
  }
  const currentRouteIndex = get(props, 'result.state.currentRoute', 0);

  const chargingStations = get(routes[currentRouteIndex], 'sections', [])
    .filter((section) => section.arrival.place.type === 'chargingStation')

  const currentRouteMarkers = [];
  chargingStations.forEach((section) => {
    if (section.arrival.place.displayLocation) {
      const { lat, lng } = section.arrival.place.displayLocation;
      currentRouteMarkers.push({
        value: `${lat},${lng}`,
        draggable: false,
        isCurrentRoute: true,
        text: '&#9679;',
        iconType: 'displayLocation',
        iconHeight: 26,
        iconWidth: 22,
      });
    }
    const { lat, lng } = section.arrival.place.location;
    currentRouteMarkers.push({
      value: `${lat},${lng}`,
      text: '&#x1f50b;',
      draggable: false,
      isCurrentRoute: true,
    });
  });

  const otherMarkers = [];
  routes
    .filter((route, index) => index !== currentRouteIndex)
    .forEach((route) => {
      get(route, 'sections', [])
        .filter((section) => section.arrival.place.type === 'chargingStation')
        .forEach((section) => {
          const { lat, lng } = section.arrival.place.location;
          const value = `${lat},${lng}`;

          if (currentRouteMarkers.find((marker) => marker.value === value)) {
            return;
          }
          if (section.arrival.place.displayLocation) {
            const { lat, lng } = section.arrival.place.displayLocation;
            otherMarkers.push({
              value: `${lat},${lng}`,
              draggable: false,
              isCurrentRoute: true,
              text: '&#9679;',
              iconType: 'displayLocation',
              iconHeight: 26,
              iconWidth: 22,
            });
          }

          otherMarkers.push({
            value,
            text: '&#x1f50b;',
            draggable: false,
            isCurrentRoute: false,
          });

        });
    });

  return [...currentRouteMarkers, ...otherMarkers];
};
