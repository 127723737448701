// eslint-disable-next-line import/no-anonymous-default-export
export default {
  evEnabled: true,

  // eslint-disable-next-line max-len
  'ev[freeFlowSpeedTable]':
    '0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351',
  // eslint-disable-next-line max-len
  'ev[trafficSpeedTable]':
    '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
  'ev[auxiliaryConsumption]': '1.8',
  'ev[ascent]': '9',
  'ev[descent]': '4.3',
};
