import formViewsPlugin from '../formViewsPlugin';
import isolineOriginDestination from './isolineOriginDestination/isolineOriginDestination';
import inspectSegment from './inspectSegment/inspectSegment';
import multiSelect from './multiSelect/multiSelect';
import loader from './loader';
import consumptionSpeedTable from './consumptionSpeedTable';
import isolinePresets from './isolinePresets';
import columns from './columns';
import checkbox from './checkbox';
import multiItems from './multiItems/multiItems';

formViewsPlugin.register('isolineOriginDestination', isolineOriginDestination);
formViewsPlugin.register('inspectSegment', inspectSegment);
formViewsPlugin.register('multiSelect', multiSelect);
formViewsPlugin.register('loader', loader);
formViewsPlugin.register('consumptionSpeedTable', consumptionSpeedTable);
formViewsPlugin.register('isolinePresets', isolinePresets);
formViewsPlugin.register('columns', columns);
formViewsPlugin.register('checkbox', checkbox);
formViewsPlugin.register('multiItems', multiItems);
