import React from 'react';
import PropTypes from 'prop-types';
import { FeatureGroup, Map, Pane, TileLayer } from 'react-leaflet';
import mapPlugin from '../../../core/mapPlugin';
import TangramLayer from './TangramLayer';
import SearchResults from './LeafletSearchResults';
import refClient from '../../../core';
import { debounce, isEqual } from 'lodash';
import { DEFAULT_MAP_CENTER, DEFAULT_ZOOM } from '../constants';
import { latLngBounds } from 'leaflet';
import {
  TILE_LIBRARY_TYPES,
  TILE_TYPES,
} from '../../../state/map/tiles/constants';
import TrafficLayer from './TrafficLayer';
import MapErrorHandler from '../MapErrorHandler';
import { logError } from '../../../utils/splunkLog';
import { getDefaultMapCenter } from '../defaultMapCenterLocalStorage';
import { getTilesUrl } from './helpers';

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);

    this.tabFeatureGroups = [];
    this.mapRef = React.createRef();
    this.resizeMapDebounced = debounce(this.resizeMap, 500);
    this.onMoveEndDebounced = debounce(this.onMoveEnd, 500);
  }

  componentDidMount() {
    const {
      bounds: { top, right, bottom, left },
    } = this.props;
    const { leafletElement } = this.mapRef.current;
    if (top) {
      const bounds = latLngBounds([bottom, left], [top, right]);
      leafletElement.fitBounds(bounds);
    } else {
      const bounds = leafletElement.getBounds();
      this.setMapBounds(bounds, leafletElement.getZoom());
    }
    window.addEventListener('resize', this.resizeMapDebounced);
  }

  componentDidUpdate(prevProps) {
    const { selectedTab, isAutoZoomOn, result, tabs, triggerAutoZoom } =
      this.props;
    const { isActive } = tabs[selectedTab];

    const isResultChanged = !isEqual(result.raw, prevProps.result.raw);
    if (
      isAutoZoomOn &&
      isActive &&
      (isResultChanged ||
        prevProps.selectedTab !== selectedTab ||
        triggerAutoZoom !== prevProps.triggerAutoZoom)
    ) {
      this.autoZoom(prevProps);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeMapDebounced);
  }

  onMoveEnd = ({ target: map }) => {
    this.setMapBounds(map.getBounds(), map.getZoom());
  };

  setMapBounds = (mapBounds, zoom) => {
    const { setMapBounds, bounds } = this.props;
    const newBounds = {
      top: mapBounds.getNorth(),
      left: mapBounds.getWest(),
      bottom: mapBounds.getSouth(),
      right: mapBounds.getEast(),
      zoom: Math.floor(zoom),
    };
    if (!isEqual(newBounds, bounds)) {
      setMapBounds(newBounds);
    }
  };

  resizeMap = () => {
    this.mapRef.current.leafletElement.invalidateSize();
    const { selectedTab, isAutoZoomOn, tabs } = this.props;
    const { isActive } = tabs[selectedTab];

    if (isAutoZoomOn && isActive) {
      this.autoZoom();
    }
  };

  autoZoom = () => {
    const { selectedTab } = this.props;
    const featureGroup = this.tabFeatureGroups[selectedTab].leafletElement;
    const map = this.mapRef.current.leafletElement;
    const bounds = featureGroup.getBounds();
    if (bounds.isValid() && !map.getBounds().contains(bounds)) {
      if (
        bounds.getNorth() === bounds.getSouth() &&
        bounds.getWest() === bounds.getEast()
      ) {
        map.flyTo(bounds.getCenter());
      } else {
        map.flyToBounds(bounds, { padding: [50, 50] });
      }
    }
  };

  renderTabsComponents = () => {
    const {
      config,
      dispatch,
      tabs,
      selectedTab,
      setResultState,
      bounds,
      setFields,
      addTab,
      setNotification,
      allSettings,
      request,
    } = this.props;
    return tabs.map((tab, tabIndex) => {
      if (!tab.isActive) {
        return null;
      }
      const { current } = this.mapRef;
      let map = current ? current.leafletElement : {};
      const { title, preset } = tab;
      const tabConfigs = config[title];
      const zoomableEls = [];
      const nonZoomableEls = [];
      tabConfigs.map.forEach((mapConfig, index) => {
        try {
          const plugin = mapPlugin.get(mapConfig.type);
          const Component = plugin[TILE_LIBRARY_TYPES.LEAFLET];
          if (!Component) {
            return;
          }
          const el = (
            <MapErrorHandler key={`${mapConfig.type}_${tabIndex}_${index}`}>
              <Component
                colorPalette={tab.tabColorPalette}
                dispatch={dispatch}
                fields={tab.fields}
                options={mapConfig.options}
                result={tab.result}
                paramsMapping={tab.paramsMapping}
                selectedTab={selectedTab}
                tabIndex={tabIndex}
                setResultState={setResultState}
                setFields={setFields}
                bounds={bounds}
                refClient={refClient}
                addTab={addTab}
                setNotification={setNotification}
                settings={allSettings[title][preset]}
                request={request}
                map={map}
              />
            </MapErrorHandler>
          );
          if (mapConfig.skipAutoZoom) {
            nonZoomableEls.push(el);
          } else {
            zoomableEls.push(el);
          }
        } catch (e) {
          logError({...e, mapConfig});
        }
      });

      const zIndex = tabIndex * -1 + 1000;
      return (
        <React.Fragment key={tabIndex}>
          <FeatureGroup
            ref={(ref) => {
              this.tabFeatureGroups[tabIndex] = ref;
            }}
          >
            <Pane className="rf-pane" style={{ zIndex }}>
              {zoomableEls}
            </Pane>
          </FeatureGroup>
          <Pane className="rf-pane" style={{ zIndex }}>
            {nonZoomableEls}
          </Pane>
        </React.Fragment>
      );
    });
  };

  render() {
    const { searchData, tilesData, styleUrl, trafficOverlays, config } = this.props;

    return (
      <Map
        contextmenu
        contextmenuWidth={165}
        onMoveEnd={this.onMoveEndDebounced}
        trackResize={false}
        ref={this.mapRef}
        center={getDefaultMapCenter() || DEFAULT_MAP_CENTER}
        zoom={DEFAULT_ZOOM}
      >
        {tilesData.type === TILE_TYPES.VECTOR ? (
          <TangramLayer tilesData={tilesData} styleUrl={styleUrl} config={config} />
        ) : (
          <TileLayer url={getTilesUrl(tilesData)} subdomains="1234" />
        )}
        <SearchResults searchData={searchData} />
        {this.renderTabsComponents()}
        {trafficOverlays.isActive && <TrafficLayer data={trafficOverlays} />}
      </Map>
    );
  }
}

LeafletMap.propTypes = {
  config: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  isAutoZoomOn: PropTypes.bool.isRequired,
  setMapBounds: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  searchData: PropTypes.object.isRequired,
  selectedTab: PropTypes.number.isRequired,
  tabs: PropTypes.array.isRequired,
  bounds: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  result: PropTypes.object.isRequired,
  tilesData: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
  styleUrl: PropTypes.string.isRequired,
  trafficOverlays: PropTypes.object.isRequired,
  allSettings: PropTypes.object.isRequired,
  triggerAutoZoom: PropTypes.number,
  request: PropTypes.func.isRequired,
};

export default LeafletMap;
