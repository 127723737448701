import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LayerToggle from './LayerToggle';
import { TILE_TYPES, TILE_LIBRARY_TYPES } from 'state/map/tiles/constants';
import VectorTilesForm from './VectorTilesForm';
import CustomToggleButton from 'shared/toggleButton';
import { FormRow, TabsControl } from '@here/ref-client-ui';
import {
  setTilesData,
  setTilesType,
  getTilesData,
  setTilesLibraryType,
} from 'state/map/tiles/reducer';
import './styles/tilesContainer.scss';
import RasterTilesForm from './RasterTilesForm';
import RasterTilesV3Form from './RasterTilesV3Form';
import { isInternal } from 'state/config'

import { bindActionCreators } from 'redux';

import * as TrafficOverlaysActions from 'state/map/trafficOverlays/actions';

const tilesData1 = [
  {label: TILE_TYPES.VECTOR, name: TILE_TYPES.VECTOR},
  {label: TILE_TYPES.RASTER, name: TILE_TYPES.RASTER},
  {label: TILE_TYPES.RASTERV3, name: TILE_TYPES.RASTERV3}
];

const tilesData2 = [
  {label: TILE_TYPES.VECTOR, name: TILE_TYPES.VECTOR},
  {label: TILE_TYPES.VECTORHARP, name: TILE_TYPES.VECTORHARP},
  {label: TILE_TYPES.RASTER, name: TILE_TYPES.RASTER},
  {label: TILE_TYPES.RASTERV3, name: TILE_TYPES.RASTERV3}
];


class TilesContainer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      formExpanded: false,
    };
  }

  onToggle = () => {
    const { formExpanded } = this.state;
    if (formExpanded) {
      this.setState({ formExpanded: false });
      window.removeEventListener('click', this.onToggle);
    } else {
      this.setState({ formExpanded: true });
      window.addEventListener('click', this.onToggle);
    }
  };

  onChange = (e) => {
    const { setTilesType, tilesData, toggleTrafficOverlay } = this.props;
    const { libraryType, isInternal, type } = tilesData;

    if (!isInternal && type === TILE_TYPES.VECTOR && libraryType === 'jsla') {
      toggleTrafficOverlay(false);
    }
    setTilesType(e);
  };

  onChangeTileLibraryType = () => {
    const {
      setTilesLibraryType,
      tilesData: { libraryType, type },
    } = this.props;
    
    setTilesLibraryType(
      libraryType === TILE_LIBRARY_TYPES.LEAFLET
        ? TILE_LIBRARY_TYPES.JSLA
        : TILE_LIBRARY_TYPES.LEAFLET
    );
    
    if (libraryType === TILE_LIBRARY_TYPES.JSLA && type === TILE_TYPES.VECTORHARP) {
      this.onChange(TILE_TYPES.VECTOR);
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  renderForm() {
    const { setTilesData, tilesData } = this.props;
    const { type, isInternal } = tilesData;
    let formData = tilesData[type];
    const onChange = setTilesData.bind(this, tilesData.type);
    switch (type) {
      case TILE_TYPES.VECTORHARP:
        return <VectorTilesForm onChange={onChange} {...{...formData, type}} isInternal={isInternal}/>;
      case TILE_TYPES.VECTOR:
          return <VectorTilesForm onChange={onChange} {...formData} isInternal={isInternal}/>;
      case TILE_TYPES.RASTER:
        return <RasterTilesForm onChange={onChange} {...formData} isInternal={isInternal} />;
      case TILE_TYPES.RASTERV3:
        return <RasterTilesV3Form onChange={onChange} {...formData} isInternal={isInternal} />;
      default:
        throw new Error(`Unsupported tiles type: "${type}"`);
    }
  }

  render() {
    const { formExpanded } = this.state;
    const tooltipText = formExpanded ? undefined : 'Map Tiles';
    const { tilesData = {} } = this.props;
    const tilesType = tilesData.type;
    return (
      <div onClick={this.stopPropagation}>
        <div className="rf-map__tile-toggle">
          <LayerToggle
            active={false}
            onChange={this.onToggle}
            tooltipText={tooltipText}
          />
        </div>
        {formExpanded && (
          <div className="rf-map__tooltip rf-map__tiles-module">
            <FormRow className="rf-map__tiles-module__vector-toggle">
              <CustomToggleButton
                checked={tilesData.libraryType === TILE_LIBRARY_TYPES.LEAFLET}
                onChange={this.onChangeTileLibraryType}
                label="Leaflet library:"
              />
            </FormRow>
            <FormRow className="rf-map__tiles-module__vector-toggle">
              <TabsControl
              tabsData={tilesData.libraryType === TILE_LIBRARY_TYPES.LEAFLET ? tilesData1 : tilesData2}
              currentTabName={tilesType}
              onChange={(e) => this.onChange(e)}
              />
            </FormRow>
            <FormRow>{this.renderForm()}</FormRow>
          </div>
        )}
      </div>
    );
  }
}

TilesContainer.propTypes = {
  setTilesData: PropTypes.func.isRequired,
  setTilesType: PropTypes.func.isRequired,
  setTilesLibraryType: PropTypes.func.isRequired,
  tilesData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let tilesData = getTilesData(state);
  const { config } = state;

  tilesData.isInternal = isInternal(config.default);
  return {
    tilesData: tilesData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTilesData: bindActionCreators(setTilesData, dispatch),
    setTilesType: bindActionCreators(setTilesType, dispatch),
    setTilesLibraryType: bindActionCreators(setTilesLibraryType, dispatch),
    toggleTrafficOverlay: bindActionCreators(TrafficOverlaysActions.toggle, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TilesContainer);
