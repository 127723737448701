import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';
import { MarkerIcon } from '@here/ref-client-ui';
import { isCoordValidAndDefined } from '../../../utils/validation/coordsValidation';
import { isConditionsSatisfied } from '../../utils';
import { get, set } from 'lodash';

class Marker extends React.Component {
  onDragEnd = (e) => {
    const { lat, lng } = e.target.getLatLng();
    const latLng = `${lat.toFixed(6)},${lng.toFixed(6)}`;
    this.setData(latLng);
  };

  onRemove() {
    this.setData(null);
  }

  setData(latLng) {
    const {
      setFields,
      options: { 
        coordsPath,
        mapAttributes,
        mapAttributesType,
        approachRouteType,
        approachRouteTypePath
      },
    } = this.props;
    if (mapAttributesType) {
      const coords = get(this.props, coordsPath).split(';');
      coords[mapAttributes] = latLng;
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), coords.join(';'));
      set(value, mapAttributesType.replace(/^fields\./, ''), 'routerOriginDest');
      setFields(value);
    } else if (approachRouteType) {
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), latLng);
      set(value, approachRouteTypePath.replace(/^fields\./, ''), 'waypoint');
      setFields(value);
    } else {
      const value = {};
      set(value, coordsPath.replace(/^fields\./, ''), latLng);
      setFields(value);
    }
  }

  render() {
    const {
      options,
      colorPalette,
      coords,
      iconSvg,
      selectedTab,
      tabIndex,
      paramsMapping = true,
      iconWidth,
      iconHeight,
      iconX, 
      iconY,
      rectIcon,
    } = this.props;
    const {
      text,
      coordsPath,
      mapAttributes,
      mapAttributesType,
      fontSize,
      strokeColor = 'black',
      conditions = [],
      draggable = true,
      isCurrentRoute = true,
      removable = false,
    } = options;

    if (!isConditionsSatisfied(this.props, conditions)) {
      return null;
    }
    let position;

    if (coords) {
      position = [coords.lat, coords.lng];
    } else {
      let waypoint = get(this.props, coordsPath, "");
      if (mapAttributesType) {
        if(get(this.props, mapAttributesType) === 'routerOriginDest') {
          waypoint = waypoint.split(';')[mapAttributes];
        }
      }
      if (!isCoordValidAndDefined(waypoint)) {
        return null;
      }
      position = waypoint.split(',').map(Number);
    }

    const svg =
      iconSvg ||
      MarkerIcon({
        title: text,
        color: isCurrentRoute ? colorPalette.primary : colorPalette.secondary,
        strokeColor,
        fontSize,
      });

    const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    const icon = new L.Icon({
      className: rectIcon ? 'leaflet-marker-icon-rect' : '',
      iconUrl,
      iconRetinaUrl: iconUrl,
      iconAnchor: [iconX || 12, iconY || 28],
      iconSize: [iconWidth || 23, iconHeight || 28],
    });

    const props = {};

    if (removable && selectedTab === tabIndex && paramsMapping) {
      props.contextmenu = true;
      props.contextmenuItems = [
        {
          text: 'Remove marker',
          index: 0,
          callback: this.onRemove.bind(this),
        },
        {
          separator: true,
          index: 1,
        },
      ];
    }

    return (
      <LeafletMarker
        // We have to trigger rerender for context menu
        key={`${selectedTab}${tabIndex}${paramsMapping}`}
        draggable={draggable && selectedTab === tabIndex && paramsMapping}
        position={position}
        icon={icon}
        onDragEnd={this.onDragEnd}
        {...props}
      />
    );
  }
}

Marker.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setFields: PropTypes.func,
  coords: PropTypes.object,
  iconSvg: PropTypes.string,
  paramsMapping: PropTypes.bool,
};

export default Marker;
