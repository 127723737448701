import React from 'react';
import PropTypes from 'prop-types';
import HerePolygon from 'shared/hereMap/HerePolygon';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';
import { addOpacityToHexColor } from '../../utils';

class Polygon extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {};
  };

  onContextMenu = (e) => {
    const { map } = this.props;
    
    e.stopPropagation();
    const {
      originalEvent: { x, y },
      viewportX,
      viewportY
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
        x: viewportX,
        y: viewportY
      },
    });

    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  }

  close = () => {
    const { map } = this.props;
    if (!this.isUnmounted) {
      this.setState({ open: false });
    }
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };

  addException = (type, e, v) => {
    const { onContextMenu, map } = this.props;
    const { position: { x, y } } = this.state;
    const xyToLatLng = ({ x, y }) => map.screenToGeo(x, y);

    onContextMenu(type, e, {
      latLng: xyToLatLng({x, y}),
      xy: { x, y },
      xyToLatLng,
      zoom: map.getZoom()
    });
  }

  render() {
    const { contextMenu, skipCorridor, positions, coatPositions, fillColor, strokeColor, zIndex, geometryIndex, draggable, resizable, onChange } = this.props;

    const style = {};
    if (fillColor) {
      style.fillColor = fillColor;
    } else if (strokeColor) {
      style.fillColor = addOpacityToHexColor(strokeColor, 0.5);
    }
    if (strokeColor) {
      style.strokeColor = strokeColor;
    }

    return <>
      <HerePolygon 
        latLngs={positions} 
        coatLatLngs={coatPositions}
        options={{ style, zIndex }}
        draggable={draggable} 
        resizable={resizable}
        geometryIndex={geometryIndex}
        onChange={onChange}
        onContextMenu={contextMenu ? this.onContextMenu : null}
      />
      {contextMenu && this.state.open && (<div className="rf-context-menu" style={{ ...this.state.position }}>
      <HereMapContextMenuItem
        label={`Add Exception Rectangle`}
        onClick={this.addException.bind(this, 'bbox')}
      />
      <HereMapContextMenuItem
        label={`Add Exception Polygon`}
        onClick={this.addException.bind(this, 'polygon')}
      />
      {!skipCorridor && (<HereMapContextMenuItem
        label={`Add Exception Corridor`}
        onClick={this.addException.bind(this, 'corridor')}
      />)}
    </div>
    )}
    </>;
  }
}

Polygon.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object).isRequired,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  zIndex: PropTypes.number,
  onChange: PropTypes.func,
};

export default Polygon;
