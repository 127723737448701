import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './customization.scss';
import { isEqual } from 'lodash';
import { Button, FormRow, Select } from '@here/ref-client-ui';
import { get } from 'lodash';

class CustomizationSearch extends React.Component {
  state = {
    tabId: 'new'
  };

  regionTypes = [
    { label: 'New Tab', value: 'new' }
  ];

  tabsDetail = {};

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setResultState,
      options: { key },
      result: { raw },
    } = this.props;
    
    const { result: { raw: prevRaw } } = prevProps;

    if (!isEqual(raw, prevRaw)) {
      setResultState({ [key]: {} });
    }
    const input = document.querySelector('.route-waypoint select');
    if (input) {
      if (this.tabsDetail[input.value]) {
        input.style.setProperty('border-top', `0.4rem solid ${this.tabsDetail[input.value].tab.tabColorPalette.primary}`);
      } else {
        input.style.setProperty('border-top', '');
      }
    }
  }

  onClick(e, index) {
    const selection = window.getSelection();
    if (selection.toString().length !== 0 || e.target.classList.contains('rf-button') || e.target.classList.contains('rf-select__field')) {
      return;
    }
    const {
      setResultState,
      options: { key },
      result: { state },
    } = this.props;
    
    const selected = get(state, key, {});
    
    if (selected.index === index) {
      const s = {};
      if (selected.hasOwnProperty('routeTab')) {
        s.routeTab = selected.routeTab;
      }
      setResultState({ [key]: s });
    } else {
      setResultState({ [key]: {...selected, index} });
    }
  };

  addRoute(item, waypointType) {
    const { 
      addTab, 
      setFields, 
      setSelectedTab,
      result: { state },
      options: { key },
    } = this.props;
    let { position, access, address } = item;
    const { lat: positionLat, lng: positionLng } = position;
    access = (access && access[0]) ? access : [position];
    const selected = get(state, key, {});
    const routeTab = selected.routeTab || this.state.tabId;
    let waypointFields;

    if (waypointType === 'via') {
      const { tab } = this.tabsDetail[routeTab] || {};
      const { fields } = tab || {};
      const { via } = fields || {};
      const vias = access.map(via => {
        const {lat, lng} = via;
        return {
          coords:  `${lat},${lng}`, 
          advanced: {
            sideOfStreetHint: `${positionLat},${positionLng}`, 
            nameHint: address.street
          }
        }
      });
      waypointFields = {
        [waypointType]: [ ...(via || []), ...vias]
      };
    } else {
      const { lat, lng } =  access[0];
      waypointFields = {
        [waypointType]: `${lat},${lng}`,
        [`${waypointType}_nameHint`]: address.street,
        [`${waypointType}_sideOfStreetHint`]: `${positionLat},${positionLng}`,
      }
    }
    // selected 'new tab' or selected tab is removed
    if (routeTab === 'new' || !this.tabsDetail.hasOwnProperty(routeTab)) {
      addTab({ title: 'Routing V8', fields: waypointFields });
    } else {
      const { index } = this.tabsDetail[routeTab];
      setFields(waypointFields, parseInt(index));
      setSelectedTab(parseInt(index));
    }
  }

  onChangeTab({ target: { value } }) {
    const {
      setResultState,
      options: { key },
      result: { state },
    } = this.props;

    const selected = get(state, key, {});
    selected.routeTab = value;
    setResultState({ [key]: selected });
  }

  getDetails(item, routeTab) {
    const {
      options: { displaySelectedKeys },
      tabs
    } = this.props;
    
    const detailsEl = Object.keys(item).filter(v => displaySelectedKeys.indexOf(v) > -1).map((key, i) => {
      return (
        <div key={key + i}>
          {key}: {JSON.stringify(item[key], null, 2)}
        </div>
      )
    });
    let routingTabExists = false;
    
    const routingTabs = tabs.map((tab, index) => { 
      if (tab.title === 'Routing V8') {
        this.tabsDetail[tab.id] = {tab, index};
        if ( ''+tab.id === routeTab ) {
          routingTabExists = true;
        }
        return {label: `${tab.tabTitle} ${index+1}`, value: tab.id+''} 
      } else return null
    }).filter(item => item);

    if( !routingTabExists && routeTab !== 'new') {
      delete this.tabsDetail[routeTab];
    }

    return <div className="rf-customization__details">{detailsEl}
      <FormRow className="route-waypoint">
        <Select
          options={this.regionTypes.concat(routingTabs)}
          value={routingTabExists ? routeTab : 'new'}
          onChange={this.onChangeTab.bind(this)}
        />
      </FormRow>
      <Button className = "routeto" onClick={() => this.addRoute(item, 'origin')} title="Route From" />
      <Button className = "routeto routebutton" onClick={() => this.addRoute(item, 'via')} title="Via" />
      <Button className = "routeto routebutton"  onClick={() => this.addRoute(item, 'destination')} title="Route To" />
      </div>;
  }

  render() {
    const {
      options: { key },
      result: { raw, state }
    } = this.props;

    let { data } = raw || { data: { items: [] } };

    const values = data.items.map((item, index) => {
      const { title } = item;
      const selected = get(state, key, {});
      const isByod = item.id && item.id.indexOf('here:')===0 ? false : true;
      const isSelected = selected.index === index;
      const routeTab = selected.routeTab || this.state.tabId;
      const listItemClasses = classnames('rf-customization', {
        'rf-customization_selected': isSelected,
        'rf-customization_byod': isByod
      });
      
      return (
        <li
        className={listItemClasses}
        onClick={((e) => this.onClick(e, index))}
        key={`${title}${index}`}
        >
        <div className="rf-customization__inner">
          <div className="rf-customization__content">{`${index+1}. ${title}`}</div>
        </div>
        {isSelected && this.getDetails(item, routeTab)}
        </li>
      );
    });

    return <div>{values}</div>;
  }
}

CustomizationSearch.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default CustomizationSearch;
