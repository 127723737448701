import React from 'react';
import PropTypes from 'prop-types';
import EditableLeafletPolygon from 'shared/editableLeafletPolygon';

function ELeafletPolygon({ 
  positions, 
  coatPositions, 
  showCoatStroke,
  contextMenu, 
  onContextMenu, 
  skipCorridor, 
  map, 
  geometryIndex, 
  draggable, 
  resizable, 
  onChange,
  color,
  fillColor
}) {

  return <EditableLeafletPolygon  
  contextMenu={contextMenu}
  onContextMenu={onContextMenu}
  skipCorridor={skipCorridor}
  map={map}
  color={color}
  fillColor={fillColor}
  positions={positions}
  coatPositions={coatPositions}
  showCoatStroke={showCoatStroke}
  geometryIndex={geometryIndex}
  draggable={draggable}
  resizable={resizable}
  onChange={onChange}
  />;
}

ELeafletPolygon.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object),
  // geometryIndex: PropTypes.number.isRequired,
};

export default ELeafletPolygon;
