import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTabs } from '../../state/tabs';

import './styles/tabs.scss';
import { getSelectedTab } from '../../state/selectedTab';
import * as TabsAction from '../../state/tabs/actions';
import * as NotificationActions from '../../state/notification/actions';
import * as SelectedTabActions from '../../state/selectedTab/actions';
import { setIsTabActive } from '../../state/tabs/tab/isActive/actions';
import TabSortable from './TabSortable';
import ShareContainer from './ShareContainer';
import ScrollControls from './ScrollControls';
import Tab from './Tab';
import { setTabTitle } from '../../state/tabs/tab/tabTitle/reducer';
import { setIsNewTabShown } from '../../state/ui/reducer';
import { getIsNewTabShown } from '../../state/ui';
import { debounce } from 'lodash';
import { DIRECTIONS } from './constants';
import { setTabColor } from '../../state/tabs/tab/tabColorPalette/actions';

const controlsMargin = 76;

export class TabsContainer extends Component {
  constructor(props) {
    super(props);
    this.canScrollDebounced = debounce(this.canScroll, 1000);
    this.tabsRef = React.createRef();
  }

  state = {
    canScrollLeft: false,
    canScrollRight: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.canScrollDebounced);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tabs.length !== this.props.tabs.length ||
      this.hasAnyTitleChanged(prevProps)
    ) {
      this.canScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.canScrollDebounced);
  }

  onTabClick = (index, e) => {
    const { setSelectedTab, selectedTab } = this.props;
    if (selectedTab !== index) {
      setSelectedTab(index);
    }
  };

  onTabRemove(index, e) {
    e.stopPropagation();
    let { tabs, onTabRemove, notify } = this.props;
    if (tabs.length === 1) {
      notify({
        message: `You can't close last tab`,
        impact: 'significant',
      });
      return;
    }
    onTabRemove(index);
  }

  onTabToggle(index, isActive, e) {
    e.stopPropagation();
    this.props.onTabToggle(index, isActive);
  }

  getNewTabButton() {
    return (
      <div className="rf-nav-tabs__add-new-tab" onClick={this.showNewTab}>
        New tab <span className="rf-nav-tabs__add-new-tab__plus">+</span>
      </div>
    );
  }

  canScroll = () => {
    const node = ReactDOM.findDOMNode(this.tabsRef.current);
    const tabNodes = node.childNodes;
    const canScrollLeft = node.scrollLeft !== 0;
    let tabsWidth = 0;
    for (let i = 0; i < tabNodes.length; i++) {
      tabsWidth += tabNodes[i].getBoundingClientRect().width;
    }
    const canScrollRight =
      tabsWidth - node.scrollLeft >
      node.getBoundingClientRect().width - controlsMargin + 5;

    if (
      canScrollRight !== this.state.canScrollRight ||
      canScrollLeft !== this.state.canScrollLeft
    ) {
      this.setState({ canScrollLeft, canScrollRight });
    }
  };

  handleSort = ({newIndex, oldIndex, nodes}) => {
    let indexes = [];
    for (let i=0;i < nodes.length;++i){
      indexes[i] = i;
    }
    indexes.splice(oldIndex, 1);
    indexes.splice(newIndex, 0, oldIndex);
    this.props.reorderTabs(indexes);
  };

  hasAnyTitleChanged = (props) =>
    !!this.props.tabs.some(
      (tab, index) => tab.title !== props.tabs[index].title
    );

  hideNewTab = () => {
    this.props.setIsNewTabShown(false);
  };

  showNewTab = () => {
    this.props.setIsNewTabShown(true);
  };

  scroll = (direction) => {
    let node = ReactDOM.findDOMNode(this.tabsRef.current);
    let tabNodes = node.childNodes;
    let rightPoint =
      direction === DIRECTIONS.RIGHT
        ? node.getBoundingClientRect().width +
          node.scrollLeft +
          5 -
          controlsMargin
        : node.scrollLeft - 6;
    let nextTabPosition = 0;
    for (let i = 0; i < tabNodes.length; i++) {
      let tab = tabNodes[i];
      let tabWidth = tab.getBoundingClientRect().width;
      nextTabPosition += tabWidth;
      if (nextTabPosition > rightPoint) {
        if (direction === DIRECTIONS.LEFT) {
          nextTabPosition -= tabWidth;
        }
        break;
      }
    }
    if (direction === DIRECTIONS.RIGHT) {
      node.scrollLeft =
        nextTabPosition - node.getBoundingClientRect().width + controlsMargin;
    } else {
      node.scrollLeft = nextTabPosition;
    }
    this.canScroll();
  };

  render() {
    let { tabs, selectedTab, setTabTitle, duplicateTab, setTabColor } = this.props;
    return (
      <nav className="rf-nav-tabs">
        {this.getNewTabButton()}
        <TabSortable 
          onSortEnd={this.handleSort}
          className="rf-nav-tabs__sortable"
          tabsRef={this.tabsRef}
          tabs={tabs}
          selectedTab={selectedTab} 
          setTabTitle={setTabTitle}
          duplicateTab={duplicateTab}
          setTabColor={setTabColor}
          onTabClick={this.onTabClick}
          onTabRemove={this.onTabRemove.bind(this)}
          onTabToggle={this.onTabToggle.bind(this)}
          Element={Tab}
        />
        <ScrollControls
          scroll={this.scroll}
          canScrollLeft={this.state.canScrollLeft}
          canScrollRight={this.state.canScrollRight}
        />
        <ShareContainer />
      </nav>
    );
  }
}

TabsContainer.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  onTabToggle: PropTypes.func.isRequired,
  onTabRemove: PropTypes.func.isRequired,
  setIsNewTabShown: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  reorderTabs: PropTypes.func.isRequired,
  setTabTitle: PropTypes.func.isRequired,
  duplicateTab: PropTypes.func.isRequired,
  setTabColor: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    tabs: getTabs(state),
    selectedTab: getSelectedTab(state),
    isNewTabShown: getIsNewTabShown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onTabToggle: bindActionCreators(setIsTabActive, dispatch),
    onTabRemove: bindActionCreators(TabsAction.removeTab, dispatch),
    setIsNewTabShown: bindActionCreators(setIsNewTabShown, dispatch),
    notify: bindActionCreators(NotificationActions.set, dispatch),
    setSelectedTab: bindActionCreators(
      SelectedTabActions.setSelectedTab,
      dispatch
    ),
    reorderTabs: bindActionCreators(TabsAction.reorderTabs, dispatch),
    setTabTitle: bindActionCreators(setTabTitle, dispatch),
    duplicateTab: bindActionCreators(TabsAction.duplicateTab, dispatch),
    setTabColor: bindActionCreators(setTabColor, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer);
