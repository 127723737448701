import React, { useEffect } from 'react';
import HerePolyline from 'shared/hereMap/HerePolyline';
import { useHereMap } from '../../../views/shared/hereMap/HereMapContext';
import PropTypes from 'prop-types';

function HereNoticesPolyline({
  withInteractions,
  positions,
  color,
  isSelected,
  selectNotice,
  setHovered,
  index,
}) {
  const style = {
    strokeColor: color,
    lineWidth: 10,
  };

  if (!isSelected) {
    style.lineDash = [1, 3];
  }
  const interactionProps = {};
  if (withInteractions) {
    interactionProps.onTap = selectNotice;
    interactionProps.onPointerLeave = () => setHovered(null);
    interactionProps.onPointerEnter = () => setHovered(index);
  }
  return (
    <HerePolyline
      latLngs={positions}
      options={{ zIndex: 500, style }}
      changeCursorOnHover
      {...interactionProps}
    />
  );
}

HereNoticesPolyline.propTypes = {
  withInteractions: PropTypes.bool.isRequired,
  positions: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectNotice: PropTypes.func.isRequired,
  setHovered: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default HereNoticesPolyline;

export const useHereOnMove = (setSelectedShape) => {
  const { map } = useHereMap();
  useEffect(() => {
    const hide = () => setSelectedShape(null);
    map.addEventListener('mapviewchangestart', hide);

    return () => {
      map.removeEventListener('mapviewchangestart', hide);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
