const toFixed6 = (val) => +val.toFixed(6);

// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e, isException, exceptionOf) => {
  const {
    avoid: { areas, features },
  } = fields;
  const {
    xy: { x, y },
    xyToLatLng,
  } = e;
  exceptionOf = exceptionOf === undefined ? areas.length : exceptionOf;

  const padding = 100;
  const left = xyToLatLng({ x: x - padding, y });
  const right = xyToLatLng({ x: x + padding, y });

  return {
    avoid: {
      areas: [
        ...areas,
        {
          geometry:[
            [toFixed6(left.lat), toFixed6(left.lng)],
            [toFixed6(right.lat), toFixed6(right.lng)],
          ],
          type: "corridor",
          radius: 100,
          isException: !!isException,
          i: exceptionOf
        }
      ],
      features,
    },
  };
};
