import React from 'react';
import PropTypes from 'prop-types';
import { isCoordValid } from 'utils/validation/coordsValidation';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import * as icons from './icons';

function Markers(MarkerComponent) {
  function Component(props) {
    const {
      options,
      colorPalette,
      result,
      tabIndex,
      refClient: { formatterPlugin },
      setFields,
      selectedTab,
      paramsMapping,
    } = props;
    const { path, format, removable, iconType } = options;

    const formatter = formatterPlugin.get(format);
    if (!formatter) {
      console.error(`Unsupported markers formatter: ${format}.`);
    }

    const coords = formatter(path ? get(props, path, '') : props, setFields);

    return coords.map((coord, index) => {
      if (isCoordValid(coord.value)) {
        const iconSvg = iconType
          ? icons[iconType](index + 1, colorPalette.primary, coord.strokeColor)
          : null;
        const iconSvgDS = coord.dsIcon ? icons[coord.dsIcon](coord.text) : null;

        return (
          <MarkerComponent
            key={`${index}${coord.value}`}
            options={{
              text: coord.text,
              coordsPath: 'fields.coord.value',
              fontSize: coord.fontSize,
              draggable: coord.draggable,
              isCurrentRoute: coord.isCurrentRoute,
              removable,
            }}
            iconSvg={iconSvgDS || iconSvg}
            rectIcon={coord.rectIcon}
            iconWidth={coord.iconWidth ? coord.iconWidth : null}
            iconHeight={coord.iconHeight ? coord.iconHeight : null}
            iconX={coord.iconX ? coord.iconX : null}
            iconY={coord.iconY ? coord.iconY : null}
            fields={{ coord }}
            result={result}
            colorPalette={coord.color || colorPalette}
            tabIndex={tabIndex}
            selectedTab={selectedTab}
            setFields={coord.onChange}
            paramsMapping={paramsMapping}
          />
        );
      }

      return null;
    });
  }

  return React.memo(Component, (prevProps, nextProps) => {
    const { options, result, tabIndex, selectedTab } = prevProps;
    const { path } = options;

    const coordsParam = path ? get(prevProps, path, '') : prevProps;

    const nextCoordsParam = path ? get(nextProps, path, '') : nextProps;

    return (
      isEqual(coordsParam, nextCoordsParam) &&
      tabIndex === nextProps.tabIndex &&
      isEqual(result, nextProps.result) &&
      selectedTab === nextProps.selectedTab
    );
  });
}

Markers.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default Markers;
