import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props, setFields) => {
  const advancedLocations = [];

  // originDestination_displayLocation is for isoline routing
  const keys = ['origin_displayLocation', 'destination_displayLocation', 'originDestination_displayLocation'];
  const keys2 = ['origin_sideOfStreetHint', 'destination_sideOfStreetHint', 'originDestination_sideOfStreetHint'];
  keys.forEach(key => {
    const dl = get(props, `fields.${key}`);
    if (dl) {
      advancedLocations.push({
        displayLocation: dl,
        type: key
      });
    }
  })
  keys2.forEach(key => {
    const dl = get(props, `fields.${key}`);
    if (dl) {
      advancedLocations.push({
        sideOfStreetHint: dl,
        type: key
      });
    }
  })

  const vias = get(props, 'fields.via');
  if (vias) {
    vias.forEach((via, i) => {
      advancedLocations.push({
        displayLocation: via.advanced.displayLocation,
        type: 'displayLocation,'+i
      })
      advancedLocations.push({
        sideOfStreetHint: via.advanced.sideOfStreetHint,
        type: 'sideOfStreetHint,'+i
      })
    });
  }

  const displayLocationMarkers = advancedLocations
    .filter(dl => dl.displayLocation !== "")
    .map((dl) => {
      const {type, displayLocation, sideOfStreetHint} = dl;
      const [lat, lng] = displayLocation ? displayLocation.split(',') : sideOfStreetHint.split(',');

      return {
        value: `${lat},${lng}`,
        draggable: true,
        isCurrentRoute: true,
        text: displayLocation ? '&#9679;' : '&#x2193;',
        iconType: 'displayLocation',
        iconHeight: 26,
        iconWidth: 22,
        onChange({ coord: { value: coords } }) {
          if (keys.indexOf(type) > -1 || keys2.indexOf(type) > -1) {
            setFields({
              [type]: coords,
            });
          } else {
            const indicator = type.split(',');
            const key = indicator[0];
            const index = indicator[1];
            
            vias[index].advanced[key] = coords;
            setFields({
              via: [...vias.slice(0, index), vias[index], ...vias.slice(index + 1)],
            });
          }
        }
      };
    });

  return displayLocationMarkers;
};
