import React from 'react';
import { Button, Tree, CopyButton } from '@here/ref-client-ui';
import PropType from 'prop-types';
import './styles/apiUrlPostData.scss';
import TextareaAutosize from 'react-textarea-autosize';
import classnames from 'classnames';
import { isEqual } from 'lodash';

const stringifyPostData = (postData, isMultiline) =>
  JSON.stringify(postData, null, isMultiline ? 2 : null);

function ApiUrlPostData({
  postData,
  isApiUrlMultiline,
  onChange,
  onChangeIsEditing,
  setNotification,
}) {
  const [isEditable, setIsEditable] = React.useState(false);
  const [prevPostData, setPrevPostData] = React.useState(postData);
  const [statePostData, setStatePostData] = React.useState(
    stringifyPostData(postData, isApiUrlMultiline)
  );

  if (!postData) {
    return null;
  }

  if (!isEqual(prevPostData, postData)) {
    setPrevPostData(postData);
  }

  let isError = false;

  const edit = () => {
    setStatePostData(stringifyPostData(postData, isApiUrlMultiline));
    setIsEditable(true);
    onChangeIsEditing(true);
  };

  const save = () => {
    try {
      const newPostData = JSON.parse(statePostData);
      onChange(newPostData);
      setIsEditable(false);
      onChangeIsEditing(false);
    } catch (e) {
      // We shouldn't end up here but this is just a precaution
    }
  };

  const cancel = () => {
    setStatePostData(stringifyPostData(postData, isApiUrlMultiline));
    setIsEditable(false);
    onChangeIsEditing(false);
  };

  const change = ({ target: { value } }) => {
    setStatePostData(value);
  };

  try {
    JSON.parse(statePostData);
  } catch (e) {
    isError = true;
  }

  const textareaClasses = classnames('rf-api-url-post-data__textarea', {
    'rf-api-url-post-data__textarea_error': isError,
  });

  const paste = async () => {
    if (!navigator.clipboard.readText) {
      setNotification({
        message: 'Your browser does not support pasting from clipboard.',
        impact: 'negative',
        autoDismiss: 5,
      });
      return;
    }
    let text;
    try {
      text = await navigator.clipboard.readText();
    } catch (e) {
      setNotification({
        message:
          'Paste operation did not succeed. Be sure you granted permissions for this operation.',
        impact: 'negative',
        autoDismiss: 5,
      });
      return;
    }

    try {
      onChange(JSON.parse(text));
    } catch (e) {
      setNotification({
        children: (
          <>
            Paste operation did not succeed. <strong>Invalid JSON</strong>
          </>
        ),
        impact: 'negative',
        autoDismiss: 5,
      });
    }
  };

  return (
    <div className="rf-api-url-post-data">
      <div className="rf-api-url-post-data__title">
        Post Data
        {!isEditable && (
          <>
            <CopyButton value={stringifyPostData(postData, isApiUrlMultiline)}>
              <div className="rf-input__btn-copy rf-api-url-copy-btn" />
            </CopyButton>
            <Button
              className="rf-paste-btn"
              onClick={paste}
              title="Paste from clipboard"
            />
          </>
        )}
      </div>
      {isEditable ? (
        <TextareaAutosize
          className={textareaClasses}
          value={statePostData}
          onChange={change}
        />
      ) : (
        <Tree data={postData} shouldExpandNode={() => isApiUrlMultiline} />
      )}
      <div className="rf-api-url-post-data__action-btns">
        {isEditable ? (
          <>
            <Button
              title="Cancel"
              onClick={cancel}
              className="rf-button_secondary"
            />
            <Button title="Save post data" disabled={isError} onClick={save} />
          </>
        ) : (
          <>
            <Button title="Edit post data" onClick={edit} />
          </>
        )}
      </div>
    </div>
  );
}

ApiUrlPostData.propTypes = {
  postData: PropType.object,
  isApiUrlMultiline: PropType.bool,
  onChange: PropType.func.isRequired,
  onChangeIsEditing: PropType.func.isRequired,
  setNotification: PropType.func.isRequired,
};

export default ApiUrlPostData;
