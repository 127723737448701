const toFixed6 = (val) => +val.toFixed(6);

// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e, isException, exceptionOf) => {
  const {
    avoid: { areas, features },
  } = fields;
  const {
    xy: { x, y },
    xyToLatLng,
  } = e;
  exceptionOf = exceptionOf === undefined ? areas.length : exceptionOf;

  const padding = 100;
  const topLeftPoint = { x: x - padding, y: y - padding };
  const bottomRightPoint = { x: x + padding, y: y + padding };
  const topLeft = xyToLatLng(topLeftPoint);
  const bottomRight = xyToLatLng(bottomRightPoint);

  return {
    avoid: {
      areas: [
        ...areas,
        {
          geometry:[
            [toFixed6(topLeft.lat), toFixed6(topLeft.lng)],
            [toFixed6(bottomRight.lat), toFixed6(bottomRight.lng)],
          ],
          type: "bbox",
          isException: !!isException,
          i: exceptionOf
        }
      ],
      features,
    },
  };
};
