import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ApiUrlContainer from '../apiUrl/ApiUrlContainer';
import FilterPanelContainer from '../filterPanel/FilterPanelContainer';
import ResultPanelContainer from '../resultPanel/ResultPanelContainer';
import NavContainer from '../navPanel/NavContainer';
import TabsContainer from '../tabsPanel/TabsContainer';
import { UI_SIZE } from 'state/appSettings/constants';
import { getTabs } from 'state/tabs';
import classnames from 'classnames';
import HistoryContainer from '../history/HistoryContainer';
import CustomNotifications from 'shared/notification';
import MapContainer from '../map/MapContainer';
import { setIsLoading } from 'state/tabs/tab/isLoading/actions';

import './styles/normalize.scss';
import './styles/main.scss';
import { getIsHistoryShown, getIsNewTabShown } from 'state/ui';

const UI_SIZE_CLASSES = {
  [UI_SIZE.SMALL]: 'lui-small',
  [UI_SIZE.MEDIUM]: 'lui-medium',
  [UI_SIZE.LARGE]: 'lui-large',
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.notificationSystemRef = React.createRef();
  }

  componentDidMount() {
    this._notificationSystem = this.notificationSystemRef.current;
    this.setStyleSize();

    const { notification } = this.props;

    if (notification) {
      this.addNotification(notification);
    }
  }

  componentDidUpdate(prevProps) {
    const { uiSize, notification } = this.props;
    if (prevProps.uiSize !== uiSize) {
      this.setStyleSize();
    }

    if (notification !== prevProps.notification) {
      this.addNotification(notification);
    }
  }

  addNotification = (notification) => {
    if (this._notificationSystem) {
      this._notificationSystem.addNotification(notification);
    }
  };

  onContextMenu(contextMenuData) {
    if (contextMenuData === this.state.contextMenuData) {
      return;
    }
    this.setState({ contextMenuData });
  }

  setStyleSize = () => {
    let { uiSize } = this.props;

    document.body.className = UI_SIZE_CLASSES[uiSize];
  };

  render() {
    let { apiUrl, isHistoryShown, postData, rcParams } = this.props;

    const contentClasses = classnames('rf-main__content rf-clearfix', {
      'rf-main__content_history': isHistoryShown,
      'rf-main__content_no-api-url-bar': apiUrl === '',
    });

    return (
      <div className="rf-main">
        <lui-default-theme dark>
          <CustomNotifications ref={this.notificationSystemRef} />
          <NavContainer />
          <TabsContainer />
          <div className={contentClasses}>
            <FilterPanelContainer />
            <MapContainer />
            <ResultPanelContainer />
          </div>
          {apiUrl && <ApiUrlContainer apiUrl={apiUrl} postData={postData} rcParams={rcParams} />}
          <HistoryContainer />
        </lui-default-theme>
      </div>
    );
  }
}

Main.propTypes = {
  apiUrl: PropTypes.string,
  notification: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  uiSize: PropTypes.number.isRequired,
  routeTimezone: PropTypes.string.isRequired,
  isHistoryShown: PropTypes.bool.isRequired,
  isNewTabShown: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  postData: PropTypes.object,
  rcParams: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    notification: state.notification,
    tabs: getTabs(state),
    isHistoryShown: getIsHistoryShown(state),
    isNewTabShown: getIsNewTabShown(state),
    uiSize: state.appSettings.uiSize,
    routeTimezone: state.appSettings.routeTimezone,
  };
}

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
