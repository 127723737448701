import React from 'react';
import PropTypes from 'prop-types';
import { divIcon } from 'leaflet';
import EditableLeafletMarker from 'shared/editableLeafletMarker';

function LeafletPolygonMarker({ position, index, geometryIndex, draggable, resizable, onChange, middlePoint, type, cursorStyle }) {
  const basicColorClass = type === 'polygonMarker' ? 'rf-polygon__polygon-marker-draggable' : 'rf-polygon__marker-draggable';
  const className = `rf-polygon__marker
  ${draggable||resizable ? ' '+basicColorClass+' rf-polygon__marker-draggable'+geometryIndex : ''}
  ${middlePoint?' rf-polygon__middle-marker':''}`;
  const icon = divIcon({
    html: `<div class="${className}">${middlePoint?'':index}</div>`,
  });
  return <EditableLeafletMarker //Marker 
    position={position} 
    icon={icon} 
    draggable={draggable} 
    resizable={resizable} 
    onChange={onChange} 
    cursorStyle={cursorStyle}
  />;
}

LeafletPolygonMarker.propTypes = {
  position: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default LeafletPolygonMarker;
