import React from 'react';
import PropTypes from 'prop-types';
import MultiplePointsComponent from '../../../views/shared/multiplePoints';
import get from 'lodash/get';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import { ExpandFormRow } from '@here/ref-client-ui';

class MultiplePoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChange = (points) => {
    const {
      options: { key },
      setFields,
    } = this.props;
    setFields({ [key]: JSON.stringify(points) });
  };

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  render() {
    const {
      options: { label, key, advancedOptions },
      fields,
    } = this.props;
    const points = JSON.parse(fields[key] || '[]');
    return (
      <ExpandFormRow
        label={label}
        isExpanded
        tooltip={this.getTooltip()}
        tooltipPlacement="right"
      >
        <MultiplePointsComponent
          options={advancedOptions}
          points={points}
          onChange={this.onChange}
        />
      </ExpandFormRow>
    );
  }
}

MultiplePoints.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData }) => {
    const { key } = options;
    const keyLowerCase = key.toLowerCase();
    if (postData) {
      return { [key]: JSON.stringify(postData[key]) };
    }
    const res = { [key]: parsedParams[keyLowerCase] || '' };
    delete parsedParams[keyLowerCase];
    return res;
  },
  defaultState: (options) => ({
    [options.key]: options.initValue || '',
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost } = options;

    if (!fields[key]) return {};

    if (isPost) {
      const points = JSON.parse(fields[key]);
      if (points.length === 0) return {};
      return {
        method: 'post',
        data: {
          [key]: points,
        },
      };
    } else {
      return {
        params: fields[key],
      };
    }
  },
  Component: MultiplePoints,
};
