import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Input } from '@here/ref-client-ui';
import set from 'lodash/set';
import get from 'lodash/get';
import './licensePlate.scss';
import { getOpenapiUrl, getOpenapiInfo } from '../openapiHelpers';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class LicensePlate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
  }

  componentDidUpdate() {
    const {
      options: { key, removeSpaces },
      fields,
      setFields,
    } = this.props;

    const field = fields[key];
    if (removeSpaces && field) {
      const noSpaces = field.replace(/\s/g, '');
      if (noSpaces !== field) {
        setFields({ [key]: noSpaces });
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChange = ({ target: { value } }) => {
    const {
      setFields,
      options: { key },
    } = this.props;
    if (value === '') {
      setFields({ [key]: `` });
    } else {
      setFields({ [key]: `lastCharacter:${value}` });
    }
  };

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-license-plate__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  render() {
    const {
      options: { key, label, inputType, placeholder },
      fields,
    } = this.props;
    const value = fields[key] || '';
    return (
      <Searchable searchKey={key}>
        <FormRow className="rf-form-view">
          <Input
            value={value.replace(/lastCharacter:/, '')}
            label={label}
            onBlur={this.onChange}
            placeholder={placeholder || label}
            type={inputType || 'text'}
            tooltip={this.getTooltip()}
            tooltipPlacement="right"
            blurOnEnter
          />
        </FormRow>
      </Searchable>
    );
  }
}

LicensePlate.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options: { key }, parsedParams }) => {
    const lowerCasedKey = key.toLowerCase();
    const licensePlate = parsedParams[lowerCasedKey];

    delete parsedParams[lowerCasedKey];
    return {
      [key]: licensePlate,
    }
  },
  defaultState: (options) => ({
    [options.key]: options.value || '',
  }),
  getRequestOptions: (fields, options) => {
    const { key, isPost, isPathParam } = options;

    if (isPathParam) {
      return {
        pathParams: { [key]: fields[key] },
      };
    }
    if (isPost) {
      const data = {};
      if (fields[key] !== '' && fields[key] != null) {
        set(data, key, fields[key]);
      }
      return {
        method: 'post',
        data,
      };
    }
    const params = (fields[key] || fields[key]===0) ? { [key]: fields[key] } : {};

    return { params };
  },
  Component: LicensePlate,
};
