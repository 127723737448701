// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, boundsList) => {
  const {
    avoid: { features },
  } = fields;
  return {
    avoid: {
      areas: boundsList,
      features,
    },
  };
};
