import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './APDetails.scss';
import get from 'lodash/get';
import { FormRow, Tree } from '@here/ref-client-ui';

class APDetails extends React.Component {
  getDetails(item) {
    if (!item.spans) {
      return null;
    }
    const { spans, polyline } = item;
    return <div className="rf-ap-details__details">
      <div className="rf-ap-details__content">
        <Tree 
          className="rf-ap-details-tree"
          data={{ spans, polyline }}
          shouldExpandNode={() => false} 
        />
      </div>
    </div>;
  }

  onClick = (e, index, key) => {
    const selection = window.getSelection();
    if (selection.toString().length !== 0) {
      return;
    }

    const {
      setResultState,
      result,
    } = this.props;
    const { target } = e;
    const selected = get(result.state, key, -1);
    const tree = document.querySelector('.rf-ap-details-tree');

    if (selected.index === index && tree && !tree.contains(target)) {
      setResultState({ [key]: -1 });
    } else if (selected !== index) {
      setResultState({ [key]: index });
    }
  };

  render() {
    const {
      result,
      result: { state },
      options: { pathKey }
    } = this.props;
    
    // const matchedLocations = get(result, 'raw.data.matchedLocations', []);
    const approachPaths = get(result, 'raw.data.approachPaths', []);

    // const matchedLocationsElems = matchedLocations.map((item, index) => {
    //   const selected = get(state, locationKey, -1);
    //   const isSelected = selected === index;
    //   const listItemClasses = classnames('rf-ap-details', {
    //     'rf-ap-details_selected': isSelected
    //   });
      
    //   return (
    //     <li
    //       className={listItemClasses}
    //       onClick={((e) => this.onClick(e, index, locationKey))}
    //       key={`${index}`}
    //     >
    //       <div className="rf-ap-details__content">{`Location Index ${index+1}`}</div>
    //     </li>
    //   );
    // });

    const approachPathsElems = approachPaths.map((item, index) => {
      const selected = get(state, pathKey, -1);
      const isSelected = selected === index;
      const listItemClasses = classnames('rf-ap-details', {
        'rf-ap-details_selected': isSelected
      });
      
      return (
        <li
          className={listItemClasses}
          onClick={((e) => this.onClick(e, index, pathKey))}
          key={`${index}`}
        >
          <div className="rf-ap-details__content">{`Path Index ${index+1}`}</div>
          {isSelected && this.getDetails(item, index)}
        </li>
      );
    });

    return <lui-default-theme>
      {/* <h4>Matched Locations</h4>
      <FormRow>
      <ul>{matchedLocationsElems}</ul>
      </FormRow> */}
      <h4>Approach Paths</h4>
      <FormRow>
      <ul>{approachPathsElems}</ul>
      </FormRow>
    </lui-default-theme>
  }
}

APDetails.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default APDetails;
