import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from '@here/ref-client-ui';

function ChargingStationsResult({ result = {} }) {
  const { state: { selectedStation } = {} } = result;

  if (!selectedStation) {
    return <h4>Please select charging station</h4>;
  }

  return (
    <>
      <h4>Selected charging station</h4>
      <Tree data={selectedStation.data} shouldExpandNode={() => true} />
    </>
  );
}

ChargingStationsResult.propTypes = {
  result: PropTypes.object,
};

export default ChargingStationsResult;
