import React, { useState } from 'react';
import get from 'lodash/get';
import { decode } from 'utils/flexPolyline';
import Notice from './Notice';
import OutsideClickHandler from 'shared/outsideClickHandler';
import PropTypes from 'prop-types';

function Notices(Polyline, useOnMove) {
  function NoticesComponent({ selectedTab, tabIndex, colorPalette, result }) {
    const [selectedShape, setSelectedShape] = useState(null);
    const [hovered, setHovered] = useState(null);
    const currentRoute = get(result, 'state.currentRoute') || 0;
    const route = get(result, ['raw', 'data', 'routes', currentRoute]);
    const selectedNotice = get(result, 'state.selectedNotice');

    useOnMove(setSelectedShape);

    if (route) {
      const res = [];
      route.sections.forEach((section) => {
        let shape;
        let shapeLength;
        if (section.polyline) {
          shape = decode(section.polyline);
          shapeLength = shape.length;
        }
        if (!section.spans || !shape) {
          return;
        }
        section.spans.forEach((span, index) => {
          if (get(span, 'notices', []).length > 0) {
            const nextSpan = section.spans[index + 1] || {
              offset: shapeLength,
            };
            const spanShape = shape
              .slice(span.offset, nextSpan.offset + 1)
              .map(([lat, lng]) => ({
                lat: +lat,
                lng: +lng,
              }));
            const notices = span.notices.map((index) => ({
              index,
              data: section.notices[index],
            }));
            res.push({ shape: spanShape, notices, sectionIndex: index });
          }
        });
      });

      const polylines = res.map(({ shape, notices, sectionIndex }, index) => {
        const isNoticeSelected =
          selectedNotice &&
          sectionIndex === selectedNotice.sectionIndex &&
          notices.find((notice) => notice.index === selectedNotice.noticeIndex);
        const isSelected =
          hovered === index ||
          get(selectedShape, 'index') === index ||
          !!isNoticeSelected;

        const selectNotice = (e) => {
          const {
            originalEvent: { clientX: left, clientY: top },
          } = e;
          setSelectedShape({
            position: { top, left },
            notices: notices.map((notice) => notice.data),
            index,
          });
        };

        return (
          <Polyline
            color={colorPalette.secondary}
            index={index}
            isSelected={isSelected}
            withInteractions={selectedTab === tabIndex}
            positions={shape}
            selectNotice={selectNotice}
            setHovered={setHovered}
            key={`${index}${selectedTab}`}
          />
        );
      });

      return (
        <>
          {polylines}
          {selectedShape && (
            <OutsideClickHandler outsideClick={() => setSelectedShape(null)}>
              <Notice
                position={selectedShape.position}
                notices={selectedShape.notices}
              />
            </OutsideClickHandler>
          )}
        </>
      );
    }

    return null;
  }

  NoticesComponent.propTypes = {
    selectedTab: PropTypes.number.isRequired,
    tabIndex: PropTypes.number.isRequired,
    colorPalette: PropTypes.object.isRequired,
    result: PropTypes.object,
  };

  return NoticesComponent;
}

export default Notices;
