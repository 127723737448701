import formattersPlugin from '../formattersPlugin';
import addMapAttributesOrigin from './addMapAttributesOrigin';
import addMapAttributesDestination from './addMapAttributesDestination';
import avoidAreaBoundingBox from './avoidAreaBoundingBox';
import avoidAreaPolygon from './avoidAreaPolygon';
import avoidAreaCorridor from './avoidAreaCorridor';
import regionDefitionAddPolygonPoint from './regionDefitionAddPolygonPoint';
import avoidAreaOnMapChange from './avoidAreaOnMapChange';
import time from './time';
import distance from './distance';
import actionsToManeuvers from './actionsToManeuvers';
import spansToManeuvers from './spansToManeuvers';
import olsMatrixRegionDefinitionBbox from './olsMatrixRegionDefinitionBbox';
import customizationSearchBbox from './customizationSearchBbox';

import olsMatrixRegionDefinitionCircle from './olsMatrixRegionDefinitionCircle';
import customizationSearchCircle from './customizationSearchCircle';
import customizationSearchAt from './customizationSearchAt';
import approachPathLocation from './approachPathLocation';
import olsVia from './olsVia';
import advancedLocations from './advancedLocations';
import evMarkers from './evMarkers';
import dsMarkers from './dsMarkers';
import customizationSearchMarkers from './customizationSearchMarkers';
import olsEvPolylines from './olsEvPolylines';
import hlsWaypoint from './hlsWaypoint';
import shapeToPositions from './shapeToPositions';
import flexPolyline from './flexPolyline';
import routeDecodingMarkers from './routeDecodingMarkers';
import { setOrigin, setDestination } from './setIsoline';
import { addVia } from './addVia';
import tbtActionsToManeuvers from './tbtActionsToManeuvers';
import vectorTileUrl from './vectorTileUrl';
import mapAttributesSegments from './mapAttributesSegments';

formattersPlugin.register('addMapAttributesOrigin', addMapAttributesOrigin);
formattersPlugin.register('addMapAttributesDestination', addMapAttributesDestination);
formattersPlugin.register('avoidAreaBoundingBox', avoidAreaBoundingBox);
formattersPlugin.register('avoidAreaPolygon', avoidAreaPolygon);
formattersPlugin.register('avoidAreaCorridor', avoidAreaCorridor);

formattersPlugin.register(
  'regionDefitionAddPolygonPoint',
  regionDefitionAddPolygonPoint
);
formattersPlugin.register('avoidAreaOnMapChange', avoidAreaOnMapChange);
formattersPlugin.register('time', time);
formattersPlugin.register('distance', distance);
formattersPlugin.register('actionsToManeuvers', actionsToManeuvers);
formattersPlugin.register('spansToManeuvers', spansToManeuvers);
formattersPlugin.register(
  'olsMatrixRegionDefinitionBbox',
  olsMatrixRegionDefinitionBbox
);
formattersPlugin.register(
  'customizationSearchBbox',
  customizationSearchBbox
);
formattersPlugin.register(
  'olsMatrixRegionDefinitionCircle',
  olsMatrixRegionDefinitionCircle
);
formattersPlugin.register(
  'customizationSearchCircle',
  customizationSearchCircle
);
formattersPlugin.register(
  'customizationSearchAt',
  customizationSearchAt
);
formattersPlugin.register('approachPathLocation', approachPathLocation);
formattersPlugin.register('olsVia', olsVia);
formattersPlugin.register('advancedLocations', advancedLocations);
formattersPlugin.register('olsEvPolylines', olsEvPolylines);
formattersPlugin.register('evMarkers', evMarkers);
formattersPlugin.register('dsMarkers', dsMarkers);
formattersPlugin.register('customizationSearchMarkers', customizationSearchMarkers);
formattersPlugin.register('hlsWaypoints', hlsWaypoint);
formattersPlugin.register('shapeToPositions', shapeToPositions);
formattersPlugin.register('flexPolyline', flexPolyline);
formattersPlugin.register('routeDecodingMarkers', routeDecodingMarkers);
formattersPlugin.register('setIsolineOrigin', setOrigin);
formattersPlugin.register('setIsolineDestination', setDestination);
formattersPlugin.register('addVia', addVia);
formattersPlugin.register('tbtActionsToManeuvers', tbtActionsToManeuvers);
formattersPlugin.register('vectorTileUrl', vectorTileUrl);
formattersPlugin.register('mapAttributesSegments', mapAttributesSegments);
