import React from 'react';
import PropTypes from 'prop-types';
import EditableLeafletPolygon from 'shared/editableLeafletPolygon';
import LeafletPolyline from 'shared/leafletPolyline';

function ELeafletCorridor({ 
  positions, positionsWithMiddle, contextMenu, onContextMenu, map, coatPositions, geometryIndex, draggable, resizable, onChange, color, fillColor 
}) {
  return <div>
    <LeafletPolyline       
      positions={positionsWithMiddle}
      coatPositions={coatPositions}
      geometryIndex={geometryIndex}
      draggable={draggable}
      resizable={resizable}
      onChange={onChange}
      color={color}
      fillColor={fillColor}
    />
    <EditableLeafletPolygon    
      contextMenu={contextMenu}
      onContextMenu={onContextMenu}
      map={map}
      positions={positions}
      coatPositions={coatPositions}
      geometryIndex={geometryIndex}
      draggable={draggable}
      resizable={resizable}
      onChange={onChange}
      color={color}
      fillColor={fillColor}
    />
  </div>;
}

ELeafletCorridor.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.object),
  // geometryIndex: PropTypes.number.isRequired,
};

export default ELeafletCorridor;