import React from 'react';
import PropTypes from 'prop-types';
import { Pane, Polyline } from 'react-leaflet';
import get from 'lodash/get';
import { decode } from 'ref-client-core/utils/flexPolyline';

function getPolyline(polyline) {
  return decode(polyline).map((latLng) => ({
    lat: +latLng[0],
    lng: +latLng[1],
  }));
}

class LeafletRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showIndicator = false;
  }

  selectPoly = (currentPath) => {
    const { setResultState } = this.props;
    setResultState({ currentPath });
  };

  onContextMenu = (e) =>  {
  }

  renderPolylines = (paths, pathPath, colorPalette) => {
    const {
      options: { selectedPath },
    } = this.props;
    const selected = get(this.props, selectedPath, -1);
    const polylines = [];
    let highlightedPane;
    paths.forEach((path, idx) => {
      const positions = getPolyline(get(path, pathPath));
      if (idx === selected) {
        highlightedPane = (
          <Pane key="highlightedRoute" style={{ zIndex: 430 }}>
            <Polyline
              key={idx}
              positions={positions}
              color={colorPalette.secondaryDarker}
              weight={8}
              onClick={() => this.selectPoly(idx)}
            />
          </Pane>
        );
      } else {
        polylines.push(
          <Polyline
            key={idx}
            positions={positions}
            color={colorPalette.secondary}
            weight={6}
            onClick={() => this.selectPoly(idx)}
          />
        );
      }
    });

    return [...polylines, highlightedPane];
  };

  render() {
    const {
      options,
      colorPalette,
    } = this.props;
    const {
      routesArrayPath,
      pathPath
    } = options;
    const polylines = get(this.props, routesArrayPath);
    if (!polylines) {
      return null;
    }

    return (
      <>
        {this.renderPolylines(
          polylines,
          pathPath,
          colorPalette
        )}
      </>
    );
  }
}

LeafletRoutes.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default LeafletRoutes;
