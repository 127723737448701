const routingConfig = {
  title: 'Search - Discover',
  tabTitle: 'Search - Discover',
  settings: {
    'EU West Discover - apiKey': {
      type: 'apiKey',
      url: 'https://eu-west-1.search.hereapi.com/v1/discover',
      apikey: '',
      isCustom: true,
      editable: true
    },
    "EU West Discover - Token": {
      type: "hereToken",
      url: 'https://eu-west-1.search.hereapi.com/v1/discover',
      skipProxy: true,
      authEndpoint: "https://account.api.here.com/oauth2/token",
      consumerKey: "",
      consumerSecret: "",
      isCustom: true,
      editable: true
    },
    'US East Discover - apiKey': {
      type: 'apiKey',
      url: 'https://us-east-1.search.hereapi.com/v1/discover',
      apikey: '',
      isCustom: true,
      editable: true
    },
    "US East Discover - Token": {
      type: "hereToken",
      url: 'https://us-east-1.search.hereapi.com/v1/discover',
      skipProxy: true,
      authEndpoint: "https://account.api.here.com/oauth2/token",
      consumerKey: "",
      consumerSecret: "",
      isCustom: true,
      editable: true
    },
  },
  inputs: [
    {
      type: "searchRegion",
      options: {
        key: 'searchRegion',
        label: 'Search Region',
        regionTypes: [
          { label: 'AT', value: 'at' },
          { label: 'Circle', value: 'circle' },
          { label: 'Bounding Box', value: 'bbox' }
        ],
        initValue: { type: 'at' },
      },
    },
    {
      type: 'input',
      options: {
        label: 'Customization HRN',
        placeholder: 'Customization HRN',
        key: 'datasets',
      },
    },
    {
      type: 'input',
      options: {
        label: 'Search',
        placeholder: 'Search (q)',
        key: 'q',
      },
    },
    {
      type: 'input',
      options: {
        label: 'Categories',
        placeholder: 'Categories',
        key: 'categories',
      },
    },
    {
      type: 'checkboxes',
      options: {
        label: 'Show',
        key: 'show',
        cssClasses: 'rf-grey-box',
        isExpanded: true,
        params: [
          { value: 'phonemes', label: 'Phonemes' },
          { value: 'streetInfo', label: 'Street Info' },
          { value: 'tz', label: 'Time Zone' },
          { value: 'byod', label: 'BYOD' }
        ]
      }
    },
    {
      type: 'input',
      options: {
        label: 'Limit',
        inputType: 'number',
        placeholder: 'Limit',
        key: 'limit',
      },
    },
  ],
  map: [
    {
      type: 'searchRegion',
    },
    {
      type: 'circle',
      options: {
        radiusPath: 'fields.searchRegion.in',
        centerPath: 'fields.searchRegion.in',
        onChangeFormatter: 'customizationSearchCircle',
        draggable: true,
        resizable: true,
      },
    },
    {
      type: 'rectangle',
      options: {
        path: 'fields.searchRegion.in',
        onChangeFormatter: 'customizationSearchBbox',
        draggable: true,
        resizable: true,
      },
    },
    {
      type: 'notices',
    },
    {
      type: 'contextMenu',
      options: {
        items: [
          {
            type: 'setFields',
            options: {
              text: 'Search at',
              key: 'searchRegion.at',
              setFieldsFormatter: 'customizationSearchAt',
              conditions: [
                {
                  type: 'equals',
                  key: 'paramsMapping',
                  value: true,
                },
              ],
            },
          },
          {
            type: 'copyLatLon',
            options: {
              text: 'Copy LatLon',
            },
          },
          {
            type: 'copyAddress',
            options: {
              text: 'Copy Address',
            },
          },
        ],
      },
    },
    {
      type: 'marker',
      options: {
        text: 'A',
        coordsPath: 'fields.searchRegion.at'
      },
    },
    {
      type: "markers",
      options: {
        text: "&#x1f50b;",
        format: "customizationSearchMarkers"
      }
    },
    {
      type: 'centerMap',
      options: {
        path: 'result.state.selectedCustomizationSearchResult',
        resultsPath: 'result.raw.data.items'
      }
    },
  ],
  resultPanel: [
    {
      type: 'tab',
      options: {
        tabs: [
          {
            title: 'Summary',
            type: 'customizationSearch',
            options: {
              key: 'selectedCustomizationSearchResult',
              displaySelectedKeys: ['distance', 'id', 'position', 'language', 'ontologyId']
            }
          },
          {
            title: 'Response',
            type: 'rawResponse',
          },
        ],
      },
    },
    {
      type: 'tabOpener',
      options: {
        tabTitle: 'Matrix Result V8',
        mapper: [
          {
            path: 'result.raw.data.statusUrl',
            fieldsPath: 'matrixResultStatusUrl',
            openTabOnChange: true,
          },
          {
            path: 'fields.origins',
            fieldsPath: 'origins',
          },
          {
            path: 'fields.destinations',
            fieldsPath: 'destinations',
          },
        ],
      },
    },
  ],
  autoRefresh: {
    conditions: [
      {
        type: 'required',
        options: {
          path: 'fields.origin',
        },
      },
      {
        type: 'required',
        options: {
          path: 'fields.destination',
        },
      },
    ],
  },
};

export default routingConfig;
