import { get, every } from 'lodash';

export function isConditionSatisfied(props, condition) {
  if (!condition) {
    return true;
  }
  const { type, key, value } = condition;
  switch (type) {
    case 'equals':
      return get(props, key) === value;
    case 'contains':
      return value.includes(get(props, key));
    default:
      return true;
  }
}

export function isConditionsSatisfied(props, conditions) {
  // console.log(conditions.length, 8888, props)
  return every(conditions, (condition) =>
    isConditionSatisfied(props, condition)
  );
}

export function addOpacityToHexColor(hex, opacity = 1) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )},${opacity})`
    : null;
}
