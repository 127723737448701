import React from 'react';
import {
  FormRow,
  Checkbox,
  CheckboxList,
  Input,
  ExpandFormRow,
  Select,
  Columns,
  Textarea,
  TabsControl,
  Button,
  Tooltip,
  CloseButton
} from '@here/ref-client-ui';
import InfoOutlined from '@here/ref-client-ui/lib/icons/InfoOutlined';
import SpeedGroup from './SpeedGroupComponent';
import PropTypes from 'prop-types';
import { get, without, find, pick, mapValues } from 'lodash';

import './olsEvConsumption.scss';
import olsElectricPreset, { preset80kWh } from './olsElectricPreset';
import yamlLoad from '../checkboxes/yamlLoad';
import { getOpenapiUrl } from '../openapiHelpers';
import isArray from 'lodash/isArray';
import Searchable from '../../../views/shared/searchUtils/Searchable';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';

const getEVImpiricalDescriptionKey = (key) => ({
  key: 'description',
  path: `components.schemas.EVEmpiricalModel.properties.${key}.description`,
});

const getEVPhysicalDescriptionKey = (key) => ({
  key: 'description',
  path: `components.schemas.EVPhysicalModel.properties.${key}.description`,
});

const getDescriptionKey = (key) => ({
  key: 'description',
  path: `components.schemas.EV.properties.${key}.description`,
});

const geConsumptionModeltDescription = (key) => ({
  key: 'description',
  path: `components.schemas.${key}.description`,
});

const consumptionModelInput = [
  { label: 'Empirical', value: 'empirical' },
  { label: 'Physical', value: 'physical' }
];

const consumptionSpeedTableKey = [
  {
    key: 'description',
    path: 'components.schemas.ConsumptionSpeedTable.description',
  },
  {
    key: 'example',
    path: 'components.schemas.ConsumptionSpeedTable.example',
  },
];

const getEvTooltipKeys = (key) => [
  getEVImpiricalDescriptionKey(key),
  {
    key: 'minimum',
    path: `components.schemas.EVEmpiricalModel.properties.${key}.minimum`,
  },
];

const getEvPhysicalTooltipKeys = (key) => [
  getEVPhysicalDescriptionKey(key),
  {
    key: 'minimum',
    path: `components.schemas.EVPhysicalModel.properties.${key}.minimum`,
  },
  {
    key: 'maximum',
    path: `components.schemas.EVPhysicalModel.properties.${key}.maximum`,
  }
];

const getEvPhysicalTooltipKeysAuxiliary = (key) => [
  getEVPhysicalDescriptionKey(key),
  {
    key: 'minimum',
    path: `components.schemas.EVPhysicalModel.properties.${key}.minimum`,
  }
]

const consumptionParameters = [
  {
    label: 'Ascent (Wh/m)',
    key: 'ev[ascent]',
    tooltipKeys: getEvTooltipKeys('ascent'),
  },
  {
    label: 'Descent (Wh/m)',
    key: 'ev[descent]',
    tooltipKeys: getEvTooltipKeys('descent'),
  },
  {
    label: 'Auxiliary (Wh/s)',
    key: 'ev[auxiliaryConsumption]',
    tooltipKeys: getEvTooltipKeys('auxiliaryConsumption'),
  },
];

const batteryParameters = [
  {
    key: 'ev[maxCharge]',
    label: 'Max charge (kWh)',
    tooltipKeys: getEvTooltipKeys('maxCharge'),
  },
  {
    key: 'ev[initialCharge]',
    label: 'Initial charge (kWh)',
    tooltipKeys: getEvTooltipKeys('initialCharge'),
  },
  {
    key: 'ev[maxChargingVoltage]',
    label: 'Max charging voltage (Volt)',
    tooltipKeys: getEvTooltipKeys('maxChargingVoltage'),
  },
  {
    key: 'ev[maxChargingCurrent]',
    label: 'Max charging current (Ampere)',
    tooltipKeys: getEvTooltipKeys('maxChargingCurrent'),
  },
];

const chargingParameters = [
  {
    key: 'ev[maxChargeAfterChargingStation]',
    label: 'Max charge after charging station (kWh)',
    tooltipKeys: getEvTooltipKeys('maxChargeAfterChargingStation'),
  },
  {
    key: 'ev[minChargeAtChargingStation]',
    label: 'Min charge at charging station (kWh)',
    tooltipKeys: getEvTooltipKeys('minChargeAtChargingStation'),
  },
  {
    key: 'ev[minChargeAtFirstChargingStation]',
    label: 'Min charge at first charging station (kWh)',
    tooltipKeys: getEvTooltipKeys('minChargeAtFirstChargingStation'),
  },
  {
    key: 'ev[minChargeAtDestination]',
    label: 'Min charge at destination',
    tooltipKeys: getEvTooltipKeys('minChargeAtDestination'),
  },
  {
    key: 'ev[chargingSetupDuration]',
    label: 'Charging setup duration (Sec)',
    tooltipKeys: getEvTooltipKeys('chargingSetupDuration'),
  },
];

const physicalConsumptionParameters = [
  {
    key: 'ev[driveEfficiency]',
    label: 'Drive Efficiency',
    tooltipKeys: getEvPhysicalTooltipKeys('driveEfficiency'),
  },
  {
    key: 'ev[recuperationEfficiency]',
    label: 'Recuperation Efficiency',
    tooltipKeys: getEvPhysicalTooltipKeys('recuperationEfficiency'),
  },
  {
    key: 'ev[auxiliaryPowerConsumption]',
    label: 'Auxiliary Power Consumption',
    tooltipKeys: getEvPhysicalTooltipKeysAuxiliary('auxiliaryPowerConsumption'),
  }
];

const chargingStationParameters = [
  {
    key: 'ev[preferredBrands]',
    label: 'Charging station brand ids',
    tooltipKeys: [getDescriptionKey('preferredBrands')],
    postable: true,
  },
  {
    key: 'ev[preferredChargePointOperators]',
    label: 'Preferred chargepoint operator ids',
    tooltipKeys: [getDescriptionKey('preferredChargePointOperators')],
    postable: true,
  },
  {
    key: 'ev[eMobilityServiceProviderPreferences]',
    labelMain: 'E-Mobility Service Provider Preference IDs',
    label1: 'eMSP id',
    label2: 'Preference Level',
    tooltipKeys: [getDescriptionKey('eMobilityServiceProviderPreferences')],
  },
];

const chargingStations = [
  {label: 'e-Mobility Service Providers', name: 'ev[eMobilityServiceProviderPreferences]'},
  {label: 'charge-point Operators', name: 'ev[preferredChargePointOperators]'},
  {label: 'Brands', name: 'ev[preferredBrands]'},
];

const connectorTypes = [
  { value: 'iec62196Type1Combo', label: 'iec62196Type1Combo' },
  { value: 'iec62196Type2Combo', label: 'iec62196Type2Combo' },
  { value: 'Chademo', label: 'Chademo' },
  { value: 'Tesla', label: 'Tesla' },
];

const eMSPPreferencelevels = [
  { label: 'High', value: 'preference=high' },
  { label: 'Medium', value: 'preference=medium' },
  { label: 'Low', value: 'preference=low' },
  { label: 'Exclude', value: 'preference=exclude' },
];

const mapParamKey = (param) => param.key;

class OlsEvConsumption extends React.Component {
  state = {
    preset: 'custom',
    openapiData: null,
    chargingStationsParam: null,
  };

  componentDidMount() {
    const propsUrl = get(this.props, 'settings.url');
    const url = getOpenapiUrl(propsUrl, '.*/v8/', 'openapi');
    yamlLoad(url).then((openapiData) => {
      if (!this.isUnmounted) {
        this.setState({ openapiData });
      }
    });

    adjustLUIStyle(document.querySelectorAll('.rf-ols-consumption lui-button'), 'lui-button');
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getTooltip(tooltipKeys) {
    const { openapiData } = this.state;
    let tooltip = '';
    if (!isArray(tooltipKeys)) {
      return null;
    }
    tooltipKeys.forEach(({ key, path }) => {
      tooltip += `${key} - ${get(openapiData, path)}\n`;
    });
    return <div className="rf-form-view__tooltip">{tooltip}</div>;
  }

  getFields(fields) {
    return fields.map((param) => {
      const field = get(this.props, `formData.fields['${param.key}']`, {});
      const evPost = get(
        this.props,
        `formData.fields.evPost['${param.key}']`,
        false
      );
      const tooltip = this.getTooltip(param.tooltipKeys);
      const postCheckbox = param.postable ? (
        <Checkbox
          label="Is POST"
          isChecked={evPost}
          onChange={this.onChangeIsPost.bind(this, param.key)}
        />
      ) : null;
      let inputEl = null;
      if (evPost) {
        inputEl = (
          <Textarea
            label={param.label}
            value={field}
            onBlur={this.onChange.bind(this, `${param.key}`)}
          />
        );
      } else {
        inputEl = (
          <Input
            label={param.label}
            placeholder={param.label}
            value={field}
            onChange={this.onChange.bind(this, `${param.key}`)}
            tooltip={tooltip}
            tooltipPlacement="right"
            debounce
          />
        );
      }
      return (
        <FormRow key={param.key}>
          {postCheckbox}
          {inputEl}
        </FormRow>
      );
    });
  }

  getChargingStationFields(fields, chargingStationsParam) {
    return fields.map((param) => {
      if (param.key !== chargingStationsParam) return null;
      const field = get(this.props, `formData.fields['${param.key}']`, {});
      const entries = field !== '' ? field.split(',') : [];
      const tooltip = this.getTooltip(param.tooltipKeys);
      if (chargingStationsParam === 'ev[eMobilityServiceProviderPreferences]') {
        const eMSPEntries = entries.map((entry, i) => {
          const eMsp = entry.split(';');
          return <Columns key={entry+i}>
              <Input
                className={'rf-emsp-id'}
                label={param.label1}
                placeholder={param.label1}
                value={eMsp[0]||'Default'}
                onBlur={this.onChangeeMSPEntry.bind(this, param.key, i, 0)}
                blurOnEnter
              />
              <Select
                className={'rf-emsp-preference'}
                label={param.label2}
                options={eMSPPreferencelevels}
                value={eMsp[1]||''}
                // addEmptyOption
                onChange={this.onChangeeMSPEntry.bind(this, param.key, i, 1)}
              />
              <Input
                className={'rf-emsp-id-empty'}
              />
              <CloseButton className="rf-entry-close-button" onClick={this.onRemoveEntry.bind(this, param.key, i)} />
            </Columns>
        })
        return (
          <FormRow key={param.key}>
              <span className="rf-input__caption">
                {param.labelMain}
                <Tooltip
                  placement="right"
                  tooltip={tooltip}
                >
                  <InfoOutlined fill="#b9bbc4" width={20} height={20} />
                </Tooltip>
              </span>
              {eMSPEntries}
              <FormRow>
                <Button
                  title="Add Entry"
                  onClick={this.onAddeMSPEntry.bind(this, param.key)}
                />
              </FormRow>
          </FormRow>
        )
      } else {
        const evPost = get(
          this.props,
          `formData.fields.evPost['${param.key}']`,
          false
        );
        const postCheckbox = param.postable ? (
          <Checkbox
            label="Is POST"
            isChecked={evPost}
            onChange={this.onChangeIsPostCharging.bind(this, param.key)}
          />
        ) : null;
        let inputEl = null;
        if (evPost) {
          inputEl = (
            <Textarea
              label={param.label}
              value={field}
              onBlur={this.onChangeCharging.bind(this, `${param.key}`)}
            />
          );
        } else {
          inputEl = (
            <Input
              label={param.label}
              placeholder={param.label}
              value={field}
              onChange={this.onChangeCharging.bind(this, `${param.key}`)}
              tooltip={tooltip}
              tooltipPlacement="right"
              debounce
            />
          );
        }
        return (
          <FormRow key={param.key}>
            {postCheckbox}
            {inputEl}
          </FormRow>
        );
      }

    });
  }

  multiUpdate = (data) => {
    const filteredData = { ...data };
    if (!this.props.makeReachableEnabled) {
      delete filteredData['ev[makeReachable]'];
    }
    if (!this.props.chargingStationParameters) {
      delete filteredData['ev[connectorTypes]'];
      delete filteredData['ev[preferredBrands]'];
      delete filteredData['ev[preferredChargePointOperators]'];
      delete filteredData['ev[eMobilityServiceProviderPreferences]'];
    }
    if (!this.props.chargeAwareRoutingEnabled) {
      delete filteredData['ev[freeFlowSpeedTable]'];
      delete filteredData['ev[trafficSpeedTable]'];
      delete filteredData['ev[ascent]'];
      delete filteredData['ev[descent]'];
      delete filteredData['ev[auxiliaryConsumption]'];
      delete filteredData['ev[acceleration]'];
      delete filteredData['ev[deceleration]'];
      delete filteredData['ev[highSpeedThreshold]'];
    }
    if (!this.props.batteryParametersEnabled) {
      delete filteredData['ev[chargingCurve]'];
      delete filteredData['ev[maxCharge]'];
      delete filteredData['ev[initialCharge]'];
      delete filteredData['ev[maxChargingVoltage]'];
      delete filteredData['ev[maxChargingCurrent]'];
    }
    if (!this.props.chargingParametersEnabled) {
      delete filteredData['ev[maxChargeAfterChargingStation]'];
      delete filteredData['ev[minChargeAtChargingStation]'];
      delete filteredData['ev[minChargeAtDestination]'];
      delete filteredData['ev[chargingSetupDuration]'];
    }
    this.props.onMultiUpdate(filteredData);
  };

  onPresetChange = (preset) => {
    const { onChange } = this.props;
    const value = preset.target ? preset.target.value : preset;

    this.setState({
      preset: value,
    });

    const newPreset = find(olsElectricPreset, { value });
    if (!newPreset) {
      return;
    }

    onChange('fuelEnabled', false);

    this.multiUpdate(newPreset.preset);
  };

  onToggle = (e) => {
    const { onChange } = this.props;

    onChange('evEnabled', e.target.checked);
    if (e.target.checked) {
      onChange('fuelEnabled', false);
    }
  };

  setDefaultConsumption = () => {
    this.multiUpdate(preset80kWh.preset);
    this.setState({
      preset: '80kwh',
    });
  };

  onChangeeMSPEntry = (key, i, e, { target: {value} }) => {
    const { onMultiUpdate } = this.props;
    const eMSP = get(this.props, `formData.fields`, {})[key];
    const eMSPEntries = eMSP.split(',');
    const modifiedEntry = eMSPEntries[i].split(';');
    modifiedEntry[e] = value;

    const modifiedeMSPEntries = [
      ...eMSPEntries.slice(0, i),
      modifiedEntry.join(';'),
      ...eMSPEntries.slice(i + 1)
    ];

    onMultiUpdate({
      ...{'ev[preferredBrands]': '', 'ev[preferredChargePointOperators]': '', 'ev[eMobilityServiceProviderPreferences]': '', [key]: modifiedeMSPEntries.join(',')}
    });
  }

  onAddeMSPEntry = (key) => {
    const { onMultiUpdate } = this.props;
    const eMSP = get(this.props, `formData.fields`, {})[key];
    const subEvPost = {'ev[preferredBrands]': false, 'ev[preferredChargePointOperators]': false};
    const entries =  `${eMSP === '' ? '': eMSP+','}Default;preference=low`;
    onMultiUpdate({
      'evPost': { ...subEvPost },
      ...{'ev[preferredBrands]': '', 'ev[preferredChargePointOperators]': '', 'ev[eMobilityServiceProviderPreferences]': '', [key]: `${entries}`}
    });
    this.setState({
      preset: 'custom',
    });
  };

  onRemoveEntry = (key, i) => {
    const { onChange } = this.props;
    const eMSP = get(this.props, `formData.fields`, {})[key];
    const eMSPEntries = eMSP.split(',');

    const modifiedeMSPEntries = [
      ...eMSPEntries.slice(0, i),
      ...eMSPEntries.slice(i + 1)
    ];

    onChange(key, modifiedeMSPEntries.join(','));
  };
  
  onChange = (key, value) => {
    this.props.onChange(key, value);
    this.setState({
      preset: 'custom',
    });
  };

  onChangeCharging = (key, { target: {value} }) => {
    const { onMultiUpdate } = this.props;
    const evPost = get(this.props, `formData.fields.evPost`, {});
    const subEvPost = {'ev[preferredBrands]': false, 'ev[preferredChargePointOperators]': false};
    delete subEvPost[key];
    onMultiUpdate({
      'evPost': { ...evPost, ...subEvPost },
      ...{'ev[preferredBrands]': '', 'ev[preferredChargePointOperators]': '', 'ev[eMobilityServiceProviderPreferences]': '', [key]: value}
    });
    this.setState({
      preset: 'custom',
    });
  };

  onToggleCharging = (e) => {
    this.setState({
      chargingStationsParam: e,
    });
  }

  onChangeIsPostCharging = (key, { target: { checked } }) => {
    const { onMultiUpdate } = this.props;
    const evPost = get(this.props, `formData.fields.evPost`, {});
    const subEvPost = mapValues(pick(evPost, ['ev[preferredBrands]', 'ev[preferredChargePointOperators]']), ()=>false);
    const subEvParams = {'ev[preferredBrands]': '', 'ev[preferredChargePointOperators]': '', 'ev[eMobilityServiceProviderPreferences]': ''};
    delete subEvParams[key];
    onMultiUpdate({
      'evPost': { ...evPost, ...subEvPost, [key]: checked },
      ...subEvParams
    });
  };

  onChangeIsPost = (key, { target: { checked } }) => {
    const { onChange } = this.props;
    let evPost = get(this.props, `formData.fields.evPost`, {});
    evPost = { ...evPost, [key]: checked };
    onChange('evPost', evPost);
  };

  onConnectorTypeChanged(value, e) {
    const { formData = {} } = this.props;
    const { fields = {} } = formData;
    const isChecked = e.target.checked;
    const currentConnectorTypes = fields['ev[connectorTypes]'];
    if (currentConnectorTypes.indexOf(value) > -1 && !isChecked) {
      this.onChange(
        'ev[connectorTypes]',
        without(currentConnectorTypes, value)
      );
    }
    if (currentConnectorTypes.indexOf(value) === -1 && isChecked) {
      this.onChange('ev[connectorTypes]', [...currentConnectorTypes, value]);
    }
  }

  getConnectorParams() {
    const { formData = {} } = this.props;
    const { fields = {} } = formData;

    return connectorTypes.map((item) => {
      const isChecked = fields['ev[connectorTypes]'].indexOf(item.value) > -1;
      return {
        ...item,
        isChecked,
        onChange: this.onConnectorTypeChanged.bind(this, item.value),
      };
    });
  }

  render() {
    const {
      formData = {},
      makeReachableEnabled,
      chargingStationParameters: chargingStationParametersEnabled,
      chargeAwareRoutingEnabled,
      batteryParametersEnabled,
      chargingParametersEnabled,
      consumptionModelEnabled,
      physicalConsumptionParametersEnabled,
    } = this.props;
    const { fields = {} } = formData;
    const { evEnabled } = fields;

    let chargingStationsParam = 'ev[eMobilityServiceProviderPreferences]';
    if (this.state.chargingStationsParam !== null) {
      chargingStationsParam = this.state.chargingStationsParam;
    } else if (fields['ev[preferredBrands]'] !== '') {
      chargingStationsParam = 'ev[preferredBrands]';
    } else if (fields['ev[preferredChargePointOperators]'] !== '') {
      chargingStationsParam = 'ev[preferredChargePointOperators]';
    } else if (fields['ev[eMobilityServiceProviderPreferences]'] !== '') {
      chargingStationsParam = 'ev[eMobilityServiceProviderPreferences]';
    }

    return (
      <ExpandFormRow
        label="EV Parameters"
        className="rf-ols-consumption"
        isExpanded
      >
        <FormRow>
          <lui-button onClick={this.setDefaultConsumption}>
            Set default values
          </lui-button>
        </FormRow>
        <FormRow>
          <Checkbox
            label="Include EV Parameters"
            isChecked={evEnabled}
            onChange={this.onToggle}
          />
        </FormRow>

        {!!olsElectricPreset.length && (
          <FormRow>
            <Select
              label="Configuration Preset"
              options={olsElectricPreset}
              value={this.state.preset}
              onChange={this.onPresetChange}
              notSupportedLabel="Custom"
            />
          </FormRow>
        )}

        {consumptionModelEnabled && (<FormRow>
          <Select
            label="Consumption Model"
            options={consumptionModelInput}
            value={fields['consumptionModel']}
            onChange={this.onChange.bind(this, 'consumptionModel')}
            tooltip={this.getTooltip([geConsumptionModeltDescription('ConsumptionModel')])}
            addEmptyOption
          />
        </FormRow>
        )}

        {makeReachableEnabled && (
          <Searchable searchKey="ev[makeReachable]">
            <FormRow>
              <Select
                label="Make Reachable"
                options={[
                  { label: 'True', value: 'true' },
                  { label: 'False', value: 'false' },
                ]}
                value={fields['ev[makeReachable]']}
                addEmptyOption
                onChange={this.onChange.bind(this, 'ev[makeReachable]')}
                tooltip={this.getTooltip([getDescriptionKey('makeReachable')])}
                tooltipPlacement="right"
              />
            </FormRow>
          </Searchable>
        )}

        {chargeAwareRoutingEnabled && (
          <Searchable
            searchKey={[
              'ev[freeFlowSpeedTable]',
              'ev[trafficSpeedTable]',
            ].concat(consumptionParameters.map(mapParamKey))}
          >
            <ExpandFormRow
              label="Charge Aware Routing"
              className="rf-battery-fields"
            >
              <ExpandFormRow
                label="Free Flow Speed Table"
                tooltip={this.getTooltip(consumptionSpeedTableKey)}
                tooltipPlacement="right"
              >
                <SpeedGroup
                  speedArray={fields['ev[freeFlowSpeedTable]'].split(',')}
                  onChange={this.onChange.bind(this, `ev[freeFlowSpeedTable]`)}
                />
              </ExpandFormRow>
              <ExpandFormRow
                label="Traffic Speed Table"
                tooltip={this.getTooltip([
                  getEVImpiricalDescriptionKey('trafficSpeedTable'),
                ])}
                tooltipPlacement="right"
              >
                <SpeedGroup
                  speedArray={fields['ev[trafficSpeedTable]'].split(',')}
                  onChange={this.onChange.bind(this, `ev[trafficSpeedTable]`)}
                />
              </ExpandFormRow>

              <Columns>{this.getFields(consumptionParameters)}</Columns>
            </ExpandFormRow>
          </Searchable>
        )}

        {batteryParametersEnabled && (
          <Searchable
            searchKey={['ev[chargingCurve]'].concat(
              batteryParameters.map(mapParamKey)
            )}
          >
            <ExpandFormRow
              label="Battery Parameters"
              className="rf-battery-fields"
            >
              <ExpandFormRow
                label="Charging curve"
                tooltip={this.getTooltip([getEVImpiricalDescriptionKey('chargingCurve')])}
                tooltipPlacement="right"
              >
                <SpeedGroup
                  speedArray={fields['ev[chargingCurve]'].split(',')}
                  onChange={this.onChange.bind(this, `ev[chargingCurve]`)}
                  vLabel="energy"
                  hLabel="power"
                  vTooltip="Energy"
                  hTooltip="Power"
                  chartType="piece-wise"
                />
              </ExpandFormRow>
              <Columns>{this.getFields(batteryParameters.slice(0, 2))}</Columns>
              {this.getFields(batteryParameters.slice(2))}
            </ExpandFormRow>
          </Searchable>
        )}

        {chargingParametersEnabled && (
          <Searchable searchKey={chargingParameters.map(mapParamKey)}>
            <ExpandFormRow
              label="Charging Parameters"
              className="rf-battery-fields"
            >
              {this.getFields(chargingParameters)}
            </ExpandFormRow>
          </Searchable>
        )}

        {chargingStationParametersEnabled && (
          <Searchable
            searchKey={['ev[connectorTypes]'].concat(
              chargingStationParameters.map(mapParamKey)
            )}
          >
            <ExpandFormRow
              label="Charging Station Parameters"
              className="rf-battery-fields"
            >
              <ExpandFormRow
                className="rf-connector-types"
                label="Connector Types"
                tooltip={this.getTooltip([getEVImpiricalDescriptionKey('connectorTypes')])}
                tooltipPlacement="right"
                isExpanded
              >
                <CheckboxList params={this.getConnectorParams()} />
              </ExpandFormRow>
              <FormRow>
                <TabsControl
                  tabsData={chargingStations}
                  currentTabName={chargingStationsParam}
                  onChange={(e) => this.onToggleCharging(e)}
                />
                {this.getChargingStationFields(chargingStationParameters, chargingStationsParam)}
              </FormRow>
            </ExpandFormRow>
          </Searchable>
        )}
        {physicalConsumptionParametersEnabled && (
          <Searchable
            searchKey={physicalConsumptionParameters.map(mapParamKey)}
          >
            <ExpandFormRow
              label="Physical Consumption Parameters"
              className="rf-battery-fields"
            >
              {this.getFields(physicalConsumptionParameters)}
            </ExpandFormRow>
          </Searchable>
        )}
      </ExpandFormRow>
    );
  }
}

OlsEvConsumption.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onMultiUpdate: PropTypes.func.isRequired,
  makeReachableEnabled: PropTypes.bool.isRequired,
  chargeAwareRoutingEnabled: PropTypes.bool.isRequired,
  batteryParametersEnabled: PropTypes.bool.isRequired,
  chargingParametersEnabled: PropTypes.bool.isRequired,
  chargingStationParameters: PropTypes.bool.isRequired,
};

export default OlsEvConsumption;
