import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isCoordsValid } from 'utils/validation/coordsValidation';

import classnames from 'classnames';
import './styles/searchBar.scss';
import { debounce } from 'lodash';
import {
  requestSearchSuggestions,
  setSearchSelected,
  clearSearch,
  setSearchSuggestions,
} from 'state/search/reducer';
import { CloseButton, Autocomplete, Loader, Tooltip } from '@here/ref-client-ui';
import InfoOutlined from '@here/ref-client-ui/lib/icons/InfoOutlined';

class SearchBar extends Component {
  constructor(...args) {
    super(...args);
    this.getSuggestionsDelayed = debounce(this.getSuggestions, 300);
    this.state = {
      isActive: false,
      searchString: '',
    };
  }

  onChange = (val, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.setState({ searchString: val });
      const { clearSearch, setSearchSuggestions } = this.props;
      if (val) {
        if (isCoordsValid(val, ';', false)) {
          const suggestions = val.split(';').map((position) => ({
            position,
            locationId: position,
            label: position,
          }));
          setSearchSuggestions(suggestions);
        } else {
          this.getSuggestionsDelayed(val);
        }
      } else {
        clearSearch();
      }
    }
  };

  onSelect = (item) => {
    const {
      setSearchSelected,
      searchData: { suggestions },
    } = this.props;
    const index = suggestions.findIndex(
      (suggestion) => suggestion.locationId === item.locationId
    );
    this.setState({ searchString: item.label, selectedItem: item });
    setSearchSelected(index);
  };

  clearSearch = () => {
    const { clearSearch } = this.props;
    this.setState({ searchString: '', selectedItem: [] });
    clearSearch();
  };

  getSuggestions = (searchString) => {
    const { requestSearchSuggestions } = this.props;
    requestSearchSuggestions(searchString);
  };

  render() {
    const {
      searchData: { suggestions, isLoading },
    } = this.props;
    const { searchString, isActive, selectedItem } = this.state;
    let addKeyToSuggestions = (suggestion) =>
      Object.assign(suggestion, { key: suggestion.locationId });
    let className = classnames('rf-search-bar', {
      'rf-search-bar_active': isActive,
    });

    return (
      <div className={className}>
        <button
          type="submit"
          aria-label="Search"
          className="rf-button rf-button-search"
        />
        <Autocomplete
          onChange={this.onChange}
          onSelect={this.onSelect}
          inputProps={{
            onFocus: () => this.setState({ isActive: true }),
            onBlur: () => this.setState({ isActive: false }),
          }}
          value={selectedItem}
          suggestions={suggestions.map(addKeyToSuggestions)}
          placeholder="Search by coords (lat,lng;lat,lng;...) or string query."
        />
        {isLoading && <Loader />}
        {searchString && <CloseButton onClick={this.clearSearch} />}
        <Tooltip
          placement="bottom"
          tooltip="Go to settings to update search language and search by bbox."
        >
          <div className="rf-search-bar__info">
            <InfoOutlined fill="#9c9fa3" width={20} height={20} />
          </div>
        </Tooltip>
      </div>
    );
  }
}

SearchBar.propTypes = {
  searchData: PropTypes.object.isRequired,
  setSearchSuggestions: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  requestSearchSuggestions: PropTypes.func.isRequired,
  setSearchSelected: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    searchData: state.search,
  };
}

const mapDispatchToProps = {
  setSearchSuggestions,
  requestSearchSuggestions,
  setSearchSelected,
  clearSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
