import PropTypes from 'prop-types';
import React from 'react';
import { ExpandFormRow, FormRow, CloseButton, Button, Input } from '@here/ref-client-ui';
import MultiplePoints from 'shared/multiplePoints';
import Searchable from '../../../views/shared/searchUtils/Searchable';
import BoundingBox from '../regionDefinition/BoundingBox';
import './avoid.scss';

class Avoid extends React.Component {

  onAddCorridor = () => {
    const {
      fields: { avoid: { areas = [], radius = 0 } = {} },
      setFields,
    } = this.props;

    setFields({
      avoid: {
        areas: [...areas, {geometry: [[], []], type: "corridor", radius, i: areas.length }],
      },
    });
  };

  onAddPolygon = () => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;

    setFields({
      avoid: {
        areas: [...areas, {geometry: [[], [], []], type: "polygon", i: areas.length}],
      },
    });
  };

  onAddBoundingBox = () => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;

    setFields({
      avoid: {
        areas: [...areas, {geometry: [[], []], type: "bbox", i: areas.length}],
        // areas: [...areas, [[null, null], [null, null]]],
      },
    });
  };

  onChangeAvoidArea = (index, bounds) => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;
    const temp = areas[index];
    
    setFields({
      avoid: {
        areas: [...areas.slice(0, index), Object.assign(temp, {geometry: bounds}), ...areas.slice(index + 1)],
      },
    });
  };

  onChangeAvoidCorridorRadius = (index, e) => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;
    const temp = areas[index];

    setFields({
      avoid: {
        areas: [...areas.slice(0, index), Object.assign(temp, {radius: e.target.value}), ...areas.slice(index + 1)],
      },
    });
  }

  onChangeAvoidCorridor = (index, bounds, encoded) => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;
    const temp = areas[index];
    if(encoded) {
      setFields({
        avoid: {
          areas: [...areas.slice(0, index), Object.assign(temp, {geometry: bounds, type: "encodedCorridor"}), ...areas.slice(index + 1)],
        },
      });
    }
    else {
      bounds = bounds.map(coord=>{
        if(coord.hasOwnProperty('lat')){
          if(coord.lat === '' || coord.lng ===  '') {
            return [];
          }
          return [coord.lat, coord.lng];
        }
        else return coord;
      })
  
      setFields({
        avoid: {
          areas: [...areas.slice(0, index), Object.assign(temp, {geometry: bounds, type: "corridor"}), ...areas.slice(index + 1)],
        },
      });
    }
  };

  onChangeAvoidPolygon = (index, bounds, encoded) => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;
    const temp = areas[index];
    
    if(encoded) {
      setFields({
        avoid: {
          areas: [...areas.slice(0, index), Object.assign(temp, {geometry: bounds, type: encoded}), ...areas.slice(index + 1)],
        },
      });
    }
    else {
      bounds = bounds.map(coord=>{
        if(coord.hasOwnProperty('lat')){
          if(coord.lat === '' || coord.lng ===  '') {
            return [];
          }
          return [coord.lat, coord.lng];
        }
        else return coord;
      })
  
      setFields({
        avoid: {
          areas: [...areas.slice(0, index), Object.assign(temp, {geometry: bounds, type: "polygon"}), ...areas.slice(index + 1)],
        },
      });
    }
  };

  onRemoveAvoidArea = (index) => {
    const {
      fields: { avoid: { areas = [] } = {} },
      setFields,
    } = this.props;

    const { i, isException } = areas[index];
    let toBeRemovedCount = 1;
    let restAreas = [];
    areas.slice(index + 1).forEach((area, idx) => {
      if (!area.isException) {
        area.i = area.i - toBeRemovedCount;
        restAreas[idx] = area;
      } else if (area.i < index) {
        restAreas[idx] = area;
      } else if (area.i > index){
        const newI = restAreas[area.i-index-1].i;
        area.i = newI;
        restAreas[idx] = area;
      } else if (!isException && area.i === i) {
        toBeRemovedCount++;
        restAreas[idx] = null;
      } else {
        console.log("Something WRONG with this condition!")
      }
    });
    restAreas = restAreas.filter(area => area !== null);

    setFields({
      avoid: {
        areas: [...areas.slice(0, index), ...restAreas],
      },
    });
  };

  getAvoidCorridor = () => {
    // {corridors}
  }

  getBboxInput = (area, index, length, i) => {
    return (
      <div className="rf-avoid__area" key={index}>
         <ExpandFormRow
          key={index}
          label={`${area.isException?'Exception':'Avoid'} Bounding Box ${index/2 + 1}`}
          isExpanded={!area.isException}
          // tooltip='description'
          tooltipPlacement="right"
          className={`rf-region-definition ${index<length-2?'':'rf-without-box'}`}
        >
          <CloseButton className="rf-avoid-close-button" onClick={this.onRemoveAvoidArea.bind(this, i)} />
          <BoundingBox
            bounds={area.geometry}
            onChange={this.onChangeAvoidArea.bind(this, i)}
          />
        </ExpandFormRow>
      </div>
    )
  }

  getPolygonInput = (area, index, length, i) => {
    let points = [];
    if (area.type === "polygon") {
      points = area.geometry.map(coord => {
        if(coord[0] !== '' && coord[1] !== '' )
          return {'lat': coord[0], 'lng': coord[1]}
        else 
          return {};
      });
    } else {
      points = area.geometry;
    }
    return (
      <div className="rf-avoid__area" key={index}>
        <ExpandFormRow
          key={index}
          label={`${area.isException?'Exception':'Avoid'} Polygon ${index/2 + 1}`}
          isExpanded={!area.isException}
          // tooltip='description'
          tooltipPlacement="right"
          className={`rf-region-definition ${index<length-2?'':'rf-without-box'}`}
        >
        <CloseButton className="rf-avoid-close-button" onClick={this.onRemoveAvoidArea.bind(this, i)} />
        <MultiplePoints
          encoded={true}
          label="Outer"
          points={points || []}
          onChange={this.onChangeAvoidPolygon.bind(this, i)}
        />
        </ExpandFormRow>
      </div>
    )
  }

  getCorridorInput = (area, index, length, i) => {
    const { radius = 0 } = area;
    let points = [];
    if (area.type === "corridor") {
      points = area.geometry.map(coord => {
        if(coord[0] !== '' && coord[1] !== '' )
          return {'lat': coord[0], 'lng': coord[1]}
        else 
          return {};
      });
    } else {
      points = area.geometry;
    }
    return (
      <div className="rf-avoid__area" key={index}>
        <ExpandFormRow
          key={index}
          label={`${area.isException?'Exception':'Avoid'} Corridor ${index/2 + 1}`}
          isExpanded={!area.isException}
          // tooltip='description'
          tooltipPlacement="right"
          className={`rf-region-definition ${index<length-2?'':'rf-without-box'}`}
        >
        <CloseButton className="rf-avoid-close-button" onClick={this.onRemoveAvoidArea.bind(this, i)} />
        <MultiplePoints
          encoded={true}
          label="Outer"
          points={points || []}
          onChange={this.onChangeAvoidCorridor.bind(this, i)}
        />
        <Input
          label="Radius"
          value={radius}
          onChange={this.onChangeAvoidCorridorRadius.bind(this, i)}
        />
        </ExpandFormRow>
      </div>
    )
  }

  render() {
    const {
      fields: { avoid: { areas = [] } = {} },
      options: { skipCorridor }
    } = this.props;

    const areasMap = {
      'bbox': {},
      'polygon': {},
      'corridor': {}
    }
    
    areas.forEach((area, index) => {
      const { i } = area;
      const { type } = areas[i];

      let adaptedType;
      if (type === "bbox") {
        adaptedType = 'bbox';
      } else if (type === "polygon" || type === "encodedPolygon") {
        adaptedType = 'polygon';
      } else if (type === "corridor" || type === "encodedCorridor") {
        adaptedType = 'corridor';
      }

      if (areasMap[adaptedType].hasOwnProperty(i)) {
        areasMap[adaptedType][i].push(area);
        areasMap[adaptedType][i].push(index);

      } else {
        areasMap[adaptedType][i] = [area, index];
      }
    })
   

    const areasInputs = {
      'bbox': [],
      'polygon': [],
      'corridor': []
    }
    
    for (const type in areasMap){
      for (const index in areasMap[type]) {
        const areaWithExceptions = areasMap[type][index].map((area, i) => {
          if (area.type === "bbox") {
            return this.getBboxInput(area, i, areasMap[type][index].length, areasMap[type][index][i+1]);
          } else if (area.type === "polygon" || area.type === "encodedPolygon") {
            return this.getPolygonInput(area, i, areasMap[type][index].length, areasMap[type][index][i+1]);
          } else if (area.type === "corridor" || area.type === "encodedCorridor") {
            return this.getCorridorInput(area, i, areasMap[type][index].length, areasMap[type][index][i+1]);
          } else return null;
        })
        
        areasInputs[type].push((
          <div className="rf-grey-box" key={index}>
            {areaWithExceptions}
          </div>
        ))
      }
    }

    return (
      <div className="rf-avoid">
        <Searchable searchKey="avoid[areas]">
          {areasInputs.bbox}
          <FormRow>
            <Button
              title="Add avoid bounding box"
              onClick={this.onAddBoundingBox}
            />
          </FormRow>
          {areasInputs.polygon}
          <FormRow>
            <Button
              title="Add avoid polygon"
              onClick={this.onAddPolygon}
            />
          </FormRow>
          {
            !skipCorridor && (
            <div>
              {areasInputs.corridor}
              <FormRow>
                <Button
                  title="Add avoid corridor"
                  onClick={this.onAddCorridor}
                />
              </FormRow>
            </div>
          )}
        </Searchable>
      </div>
    );
  }
}

Avoid.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  settings: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

const defaultState = {
  avoid: {
    areas: [],
  },
};


const extractArea = (area) => {
  if (area.type === "bbox") {
    const [topRight = [], bottomLeft = []] = area.geometry;
    return `bbox:${+topRight[1]},${+bottomLeft[0]},${+bottomLeft[1]},${+topRight[0]}`;
  } else if (area.type === "polygon") {
    const polygon = area.geometry.map(([lat, lng]) => `${+lat},${+lng}`);
    return `polygon:${polygon.join(';')}`;
  } else if (area.type === "corridor") {
    const corridor = area.geometry.map(([lat, lng]) => `${+lat},${+lng}`);
    const { radius = 0 } = area;
    return `corridor:${corridor.join(';')};r=${radius}`;
  } else if (area.type === "encodedCorridor") {
    const { radius = 0 } = area;
    return `corridor:${area.geometry[0]};r=${radius}`;
  } else {  // area.type === "encodedPolygon"
    return `polygon:${area.geometry[0]}`;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options, postData }) => {
    const { isPost } = options;

    if (!isPost) {
      const areasParam = parsedParams['avoid[areas]'] || '';
      const areasStr = areasParam.split('|').filter(Boolean);
      const areas = [];
      
      for(let avoidIndex in areasStr) {
        avoidIndex = parseInt(avoidIndex);
        const areasWExceptions = areasStr[avoidIndex].split('!exception=');
        
        for ( let idx in areasWExceptions) {
          idx = parseInt(idx);
          const isException = (idx === 0 ? false : true);
          const i = areas.length - idx;
          const [type, shape = ''] = areasWExceptions[idx].split(':');

          // bbox
          if(type === 'bbox') {
            const coords = shape.split(',');
            areas.push({
              geometry: [
                [coords[3], coords[0]],
                [coords[1], coords[2]],
              ],
              type: "bbox",
              isException,
              i
            });
          }
          // corridor
          else if (type === 'corridor') {
            const coords = shape.split(';');
            let radius = coords[coords.length - 1];
            if ((new RegExp(/^(r=)[0-9]*$/)).test(radius)) {
              coords.pop();
              radius = radius.split('=')[1] * 1;
            } else {
              radius = 0;
            }
            // corridor encoded
            if (shape.split(',').length === 1) {
              areas.push({
                geometry: [coords[0]],
                type: "encodedCorridor",
                radius,
                isException,
                i
              })
            } 
            else if (coords.length >= 1) {
              areas.push({
                geometry: coords.map(coord=>coord.split(',')),
                type: "corridor",
                radius,
                isException,
                i
              })
            } else {
              // this options should be not possible
              areas.push({
                geometry: [''],
                type: "encodedCorridor",
                radius: 0,
                isException,
                i
              })
            }
          }
          // polygon
          else {
            // polygon encoded
            if (shape.split(',').length === 1) {
              areas.push({
                geometry: [shape],
                type: "encodedPolygon",
                isException,
                i
              })
            } else if (shape.split(';').length >= 2) {
              const coords = shape.split(';');
              areas.push({
                geometry: coords.map(coord=>coord.split(',')),
                type: "polygon",
                isException,
                i
              })
            } else {
              // this options should be not possible
              areas.push({
                geometry: [''],
                type: "encodedPolygon",
                isException,
                i
              })
            }
          }
        }
      };
      delete parsedParams['avoid[areas]'];

      return {
        avoid: {
          areas,
        },
      };
    } else if (postData && postData.avoid) {
      return {
        avoid: {
          areas: (postData.avoid.areas || []).map((area, i) => {
            if (area.type === "polygon") {
              const points = area.outer.map(({lat, lng})=>[lat, lng]);
              return {
                geometry: points,
                type: "polygon",
                i
              }
            } else if ( area.type === "encodedPolygon"){
              return {
                geometry: [area.outer],
                type: "encodedPolygon",
                i
              }
            } else {  // area.type === "boundingBox"
              return {
                geometry: [
                  [area.north, area.east],
                  [area.south, area.west],
                ],
                type: "bbox",
                i
              }
            }
          }),
        },
      };
    }
    return {
      ...defaultState,
    };
  },

  defaultState: () => ({
    ...defaultState,
  }),

  getRequestOptions: (fields, options) => {
    const { avoid: { areas = [] } = {} } = fields;
    const { isPost } = options;

    if (!isPost) {
      const params = {};
      if (areas.length) {
        let avoidAreas = [];
        
        for (const i in areas) {
          const area = areas[i];
          const areaStr = extractArea(area);
          if (avoidAreas.hasOwnProperty(area['i'])) {
            if (area.isException){
              avoidAreas[area['i']].push(areaStr);
            } else 
              // this line is actually will never be called, as fist area (not exception) in string is the first one added to array
              avoidAreas[area['i']].unshift(areaStr);
          } else {
            avoidAreas[area['i']] = [areaStr];
          }
        }
        avoidAreas = avoidAreas.filter(n => n);
        
        params['avoid[areas]'] = avoidAreas.map((areasWExceptions) => {
          return areasWExceptions.join('!exception=');
        })
        .join('|');
      }
      return { params };
    }

    if (areas.length === 0) {
      return {};
    }
    const avoid = {};
    if (areas.length > 0) {
      avoid.areas = areas.map((area) => {
        if(area.type === "bbox") {
          const [topRight = [], bottomLeft = []] = area.geometry;
          return {
            type: 'boundingBox',
            north: (topRight[0] === undefined || topRight[0] === null) ? null : +topRight[0],
            south: (bottomLeft[0] === undefined || bottomLeft[0] === null) ? null : +bottomLeft[0],
            east:  (topRight[1] === undefined || topRight[1] === null) ? null : +topRight[1],
            west:  (bottomLeft[1] === undefined || bottomLeft[1] === null) ? null : +bottomLeft[1],
          };
        } else if (area.type === "polygon") {
          const geometry = area.geometry.map(([lat, lng])=>{
            return {
              lat: (lat === undefined || lat === null) ? null : +lat,
              lng: (lng === undefined || lng === null) ? null : +lng,
            }
          });
          return {
            type: 'polygon',
            outer: geometry,
          }
        } else { // area.type === "encodedPolygon"
          return {
            type: "encodedPolygon",
            outer: area.geometry[0]
          }
        }
      });
    }
    return {
      data: { avoid },
    };
  },
  Component: Avoid,
};
